<mat-card style="width: 90vw; height: 75vh; position: absolute; left: 5vw" fxLayout="column" fxLayoutAlign="space-evenly center">
    <h2>Download Test Report</h2>
    <div fxLayout="column" fxLayoutAlign="center space-evenly" style="border: 1px solid whitesmoke; border-radius: 4px; padding: 12px;">
        <mat-form-field appearance="outline">
            <mat-label>From Date</mat-label>
            <input matInput type="datetime-local" [(ngModel)]="fromDate">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>To Date</mat-label>
            <input matInput type="datetime-local" [(ngModel)]="toDate">
        </mat-form-field>
    </div>

    <button mat-raised-button color="primary" (click)="jspdfGenerate()"><mat-icon>download</mat-icon> Download</button>
</mat-card>
