<div fxFlex fxLayoutAlign="start start" fxLayout="row">

    <!-- <mat-card class="mat-elevation-z16" style="position: absolute; left: 8px;top: 15vh; border: 2px solid white"> -->
    <div>
        <mat-card class="mat-elevation-z16"
            [ngClass]="{'active-report':activeReport == 0,'inactive-report':activeReport != 0}">
            <div fxLayout="column">
                <mat-form-field appearance="outline">
                    <mat-label>Select Client Actions Report</mat-label>
                    <mat-select [(ngModel)]="selectedClient">
                        <mat-option>None</mat-option>
                        <mat-option *ngFor="let client of clientList"
                            [value]="client.clientName">{{client.clientName}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-raised-button color="primary" (click)="getReportUrl(selectedClient)">Load Report</button>
            </div>
        </mat-card>

        <mat-card class="mat-elevation-z16"
            [ngClass]="{'active-report':activeReport == 1,'inactive-report':activeReport != 1}">
            <div fxLayout="column">
                <mat-form-field appearance="outline">
                    <mat-label>Select Client API Report</mat-label>
                    <mat-select [(ngModel)]="selectedClient">
                        <mat-option>None</mat-option>
                        <mat-option *ngFor="let client of clientList"
                            [value]="client.clientName">{{client.clientName}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-raised-button color="primary" (click)="getAPIReportUrl(selectedClient)">Load Report</button>
            </div>
        </mat-card>

        <mat-card class="mat-elevation-z16"
            [ngClass]="{'active-report':activeReport == 2,'inactive-report':activeReport != 2}">
            <div fxLayout="column">
                <mat-form-field appearance="outline">
                    <mat-label>Select Client Alarm Report</mat-label>
                    <mat-select [(ngModel)]="selectedClient">
                        <mat-option>None</mat-option>
                        <mat-option *ngFor="let client of clientList"
                            [value]="client.clientName">{{client.clientName}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-raised-button color="primary" (click)="getAlarmReportUrl(selectedClient)">Load
                    Report</button>
            </div>
        </mat-card>

        <mat-card class="mat-elevation-z16"
            [ngClass]="{'active-report':activeReport == 3,'inactive-report':activeReport != 3}">
            <div fxLayout="column">

                <button mat-raised-button color="primary" (click)="getDevicePingUrl()">Load Report</button>
            </div>
        </mat-card>
    </div>



    <div style="padding: 0px; width: 85vw; height: 78vh; ">
        <iframe [src]="reportUrl" width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen
            *ngIf="activeReport == 0"></iframe>
        <iframe [src]="apiReportUrl" width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen
            *ngIf="activeReport == 1"></iframe>
        <iframe [src]="alarmReportUrl" width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen
            *ngIf="activeReport == 2"></iframe>
        <iframe [src]="devicePingReportUrl" width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen
            *ngIf="activeReport == 3"></iframe>
    </div>
</div>