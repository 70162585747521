<div class="myHeader">
    User Management
</div>

<div class="filter-container">
    <mat-form-field>
        <mat-label>
            Filter <mat-icon>search</mat-icon>
        </mat-label>
        <input matInput [(ngModel)]="filterValue" (keyup)="applyUsersFilter($event)">
    </mat-form-field>
</div>

<div class="table-container">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" style="width: 100%">
        <ng-container matColumnDef="username">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Username </th>
            <td mat-cell *matCellDef="let element"> {{element.username}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="surname">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Surname </th>
            <td mat-cell *matCellDef="let element"> {{element.surname}} </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <ng-container matColumnDef="cellphone">
            <th mat-header-cell *matHeaderCellDef> Cellphone </th>
            <td mat-cell *matCellDef="let element"> {{element.cellphone}} </td>
        </ng-container>


        <ng-container matColumnDef="admin">
            <th mat-header-cell *matHeaderCellDef> Admin </th>
            <td mat-cell *matCellDef="let element">
                <mat-icon style="color: limegreen" *ngIf="element.isAdmin==true">check_circle</mat-icon>
                <mat-icon style="color: red" *ngIf="element.isAdmin==false">cancel</mat-icon>
            </td>
        </ng-container>


        <ng-container matColumnDef="vkeys">
            <th mat-header-cell *matHeaderCellDef> v-Keys </th>
            <td mat-cell *matCellDef="let element">
                <mat-icon style="color: limegreen" *ngIf="element.generateTokens==true">check_circle</mat-icon>
                <mat-icon style="color: red" *ngIf="element.generateTokens==false">cancel</mat-icon>
            </td>
        </ng-container>


        <ng-container matColumnDef="configurator">
            <th mat-header-cell *matHeaderCellDef> Configure </th>
            <td mat-cell *matCellDef="let element">
                <mat-icon style="color: limegreen" *ngIf="element.canConfigure==true">check_circle</mat-icon>
                <mat-icon style="color: red" *ngIf="element.canConfigure==false || element.canConfigure==undefined">cancel</mat-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="subcontractor">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Subcontractor </th>
            <td mat-cell *matCellDef="let element"> {{element.subcontractor}} </td>
        </ng-container>


        <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef> Edit </th>
            <td mat-cell *matCellDef="let element"> <mat-icon (click)="selectedUser = element">edit</mat-icon> </td>
        </ng-container>

        <ng-container matColumnDef="linked">
            <th mat-header-cell *matHeaderCellDef> Linked </th>
            <td mat-cell *matCellDef="let element">
                <mat-icon *ngIf="element.device != null && element.device != undefined && element.device.linked == true"
                    style="color: lime;">radio_button_checked</mat-icon>
                <mat-icon *ngIf="element.device == null || element.device == undefined || element.device.linked != true"
                    style="color: red;">radio_button_checked</mat-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="clientdb">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Client </th>
            <td mat-cell *matCellDef="let element"> {{element.clientdb}} </td>
        </ng-container>


        <ng-container matColumnDef="filter">
            <th mat-header-cell *matHeaderCellDef>
                <mat-form-field>
                    <mat-label>Filter <mat-icon>search</mat-icon></mat-label>
                    <input matInput="filterValue">
                </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element"></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectedUser = row"></tr>
    </table>


</div>

<button class="btn"
    style="position: absolute; bottom: 7vh; z-index: 3001; color: black; background-color: lime; left: 8px;"
    (click)="addingUser = true"><mat-icon>person_add</mat-icon> Add User</button>
<mat-paginator #myPaginator [length]="dataSource.data.length" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"
    aria-label="Select page" style="position: absolute; bottom: 6vh; width: 100vw;">
</mat-paginator>



<!-- Add User -->
<div *ngIf="addingUser" class="backdrop" (click)="addingUser = false"
    style="position: fixed; width: 100vw; height: 100vh; top: 0; z-index: 3004; background-color: rgba(100,100,100,0.5);">
</div>

<div *ngIf="addingUser"
    style="position: absolute; width:auto; left: 35vw; top: 10vh; border:2px solid white; z-index: 3005;" cdkDrag>
    <app-add-user (close)="addingUser = false; getUsers()" [clientList]="allClients" ></app-add-user>
  </div>

<!-- Edit User Panel -->
<div *ngIf="selectedUser != null" class="backdrop" (click)="selectedUser = null"
    style="position: fixed; width: 100vw; height: 100vh; top: 0; z-index: 3004; background-color: rgba(100,100,100,0.5);">
</div>

<div class="editPanel" cdkDrag
style="position: absolute; left: 30vw; top: 5vh; border: 1px solid white; z-index: 3011;"
*ngIf="selectedUser != null && selectedUser != {}">

<button mat-mini-fab color="primary" style="position: absolute; top: 4px; right: 4px;"
    (click)="selectedUser = null">
    <mat-icon>close</mat-icon>
</button>
<div fxLayout="column">
    <h2>Edit User</h2>
    <div fxLayout="row">
        <div>
            <label>Details for User:</label>
            <div fxLayout="column" *ngIf="selectedUser != {}">
                <!-- ID -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>ID</mat-label>
                        <input matInput [(ngModel)]="selectedUser._id" disabled>
                    </mat-form-field>
                </div>
                <!-- Email -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput [(ngModel)]="selectedUser.email" disabled>
                    </mat-form-field>
                </div>
                <!-- Username -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Username</mat-label>
                        <input matInput [(ngModel)]="selectedUser.username">
                    </mat-form-field>
                </div>
                <!-- Name -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Name</mat-label>
                        <input matInput [(ngModel)]="selectedUser.name">
                    </mat-form-field>
                </div>

                <!-- Surname -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Surname</mat-label>
                        <input matInput [(ngModel)]="selectedUser.surname">
                    </mat-form-field>
                </div>

                <!-- Subcontractor -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Sub-Contractor</mat-label>
                        <mat-select [(ngModel)]="selectedUser.subcontractor">
                            <mat-option value="None">None</mat-option>
                            <mat-option *ngFor="let contractor of subcontractors"
                                [value]="contractor.name">{{contractor.name}}
                                ({{contractor.clientdb}})</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>


                <div fxLayout="row">
                    <div style="margin-right: 8px">
                        <!-- Admin -->
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-checkbox [(ngModel)]="selectedUser.isAdmin">Admin</mat-checkbox>
                        </div>
                        <!-- sysAdmin -->
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-checkbox [(ngModel)]="selectedUser.sysAdmin">System Admin</mat-checkbox>
                        </div>
                        <!-- Temp Admin -->
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-checkbox [(ngModel)]="selectedUser.tempAdmin">Temp Admin</mat-checkbox>
                        </div>
                    </div>

                    <div>
                        <!-- Tokens -->
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-checkbox [(ngModel)]="selectedUser.generateTokens">Can Generate Tokens
                            </mat-checkbox>
                        </div>
                        <!-- Configure -->
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-checkbox [(ngModel)]="selectedUser.canConfigure">Can Configure
                            </mat-checkbox>
                        </div>
                         <!-- Approve Users -->
                         <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-checkbox [(ngModel)]="selectedUser.canApproveUsers">Can Approve Users
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
                

                <!-- Cellphone -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Cellphone</mat-label>
                        <input matInput [(ngModel)]="selectedUser.cellphone">
                    </mat-form-field>
                </div>

                <!-- Password -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>PWD</mat-label>
                        <input matInput [(ngModel)]="selectedUser.pwd" disabled>
                    </mat-form-field>
                    <button mat-mini-fab style="background-color: lightgreen; color:black"
                        (click)="resetPassword()">
                        <mat-icon>restart_alt</mat-icon>
                    </button>
                </div>

            </div>
        </div>
        <div>
            <!-- Sites -->
            <div class="editPanel" style="height: 50vh; max-height: 50vh; overflow-y: scroll;">
                <div *ngFor="let site of selectedUser.sites">
                    <div>
                        <div>{{site.siteName}} ({{site.siteID}})</div>
                        <mat-divider></mat-divider>
                    </div>
                </div>
                <button mat-mini-fab
                    style="background-color: lightgreen; color:black; position: absolute; right: 25px; top: 54px;"
                    (click)="addingUserSites = !addingUserSites">
                    <mat-icon>edit</mat-icon>
                </button>
            </div>

            <app-edit-user-sites *ngIf="addingUserSites" [siteList]="allSites"
                [activeSites]="selectedUser.sites" (close)="addingUserSites = false;"
                (savedSites)="selectedUser.sites=$event; addingUserSites = false;"></app-edit-user-sites>

            <!-- Client -->
            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field appearance="outline">
                    <mat-label>Clients</mat-label>
                    <mat-chip-list #chipListUserClients>
                        <mat-chip *ngFor="let client of selectedUser.clientdb"
                            (removed)="removeClientFromUser(client)">{{client}}<button matChipRemove
                                fxLayout="row" fxLayoutAlign="center center"
                                style="background: transparent; border: transparent">
                                <mat-icon>cancel</mat-icon>
                            </button></mat-chip>
                    </mat-chip-list>
                    <input placeholder="" [matChipInputFor]="chipListUserClients">
                </mat-form-field>
                <mat-form-field style="width: auto;" *ngIf="addingUserClients" appearance="outline">
                    <mat-select [(ngModel)]="selectedUser.clientdb" multiple>
                        <mat-option *ngFor="let client of allClients" [value]="client.clientName">
                            {{client.clientName}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-mini-fab style="background-color: lightgreen; color:black"
                    (click)="addingUserClients = !addingUserClients">
                    <mat-icon>add</mat-icon>
                </button>

            </div>
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-around center">
        <button style="margin: 4px;" mat-raised-button color="warn" (click)="deleteUser()">
            <mat-icon>delete</mat-icon> Delete User
        </button>
        <button style="margin: 4px;" mat-raised-button color="primary" (click)="updateUser()">
            <mat-icon>save</mat-icon> Update User
        </button>
    </div>


</div>
</div>