import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-add-subcontractor',
  templateUrl: './add-subcontractor.component.html',
  styleUrls: ['./add-subcontractor.component.scss']
})
export class AddSubcontractorComponent implements OnInit {

  selectedContractor: any;
  @Input() clients: any = null;

  @Input() contractor: any = null;
  @Output() close = new EventEmitter();

  isEdit: boolean = false;
  
  constructor(public webReq: WebRequestsService) { }

  ngOnInit(): void {
    if(this.contractor == null) {
      this.selectedContractor = {
        name: "",
        address: "",
        contactNumber: "",
        contactEmail: "",
      };
    } else {
      this.selectedContractor = this.contractor;
      this.isEdit = true;
    }
  }

  save() {
    if(this.isEdit) {
      this.webReq.updateContractor(this.selectedContractor).subscribe(res => {
        console.log(res)
        alert(res.message)
        this.close.emit();
      });
    } else {
      this.webReq.addContractor(this.selectedContractor).subscribe(res => {
        console.log(res)
        alert(res.message)
        this.close.emit();
      });
    }
    
  }

  delete() {
    this.webReq.deleteContractor(this.selectedContractor).subscribe(res => {
      console.log(res)
      alert(res.message)
      this.close.emit();
    });
  }

  closing() {
    this.close.emit();
  }


}
