import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-qc-report',
  templateUrl: './qc-report.component.html',
  styleUrls: ['./qc-report.component.scss']
})
export class QcReportComponent implements OnInit {
  deviceTests = new MatTableDataSource<any>();
  sensorTests = new MatTableDataSource<any>();

  dsList : any;
  unitList: any;
  scanner: boolean = false;
  tempSerial = "";
  confirmSN: boolean = false;
  tempSensor: any = {};
  qcReporting: boolean = false;

  displayedColumns: string[] = ['serialNumber', 'time', 'result', 'version'];
  displayedColumns1: string[] = ['serialNumber', 'time', 'result'];

  @ViewChild('paginatorSensors') paginatorSensors!: MatPaginator;
  @ViewChild('paginatorDevices') paginatorDevices!: MatPaginator;
  // @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('sensorTableSort') sensorTableSort!: MatSort;
  @ViewChild('deviceTableSort') deviceTableSort!: MatSort;
  
  ngAfterViewInit() {
    this.deviceTests.paginator = this.paginatorDevices;
    this.sensorTests.paginator = this.paginatorSensors;
    this.sensorTests.sort = this.sensorTableSort;
  }
  constructor(private router: Router, private webReq: WebRequestsService) { }

  ngOnInit(): void {
    this.getDSTestResults();
    this.getTestResults();
  }

  
  getDSTestResults() {
    this.webReq.getDSTestResults().subscribe(results => {
      this.dsList = results;
      this.sensorTests.data = results;
      this.sensorTests.paginator = this.paginatorSensors;
      this.sensorTests.sort = this.sensorTableSort;
    });
  }
  getTestResults() {
    this.webReq.getTestResults().subscribe(results => {
      this.unitList = results;
      this.deviceTests.data = results;
      this.deviceTests.paginator = this.paginatorDevices;
      this.deviceTests.sort = this.deviceTableSort;
    })
  }

  goToBT() {
    this.router.navigate(['']);
  }
  saveSN() {
    console.log("Serial: " + this.tempSerial);
    console.log("Sensor: " + JSON.stringify(this.tempSensor));
    this.tempSensor.serialNumber = this.tempSerial;
    this.webReq.updateDSResultsSN(this.tempSensor).subscribe(result => {
      if (result.success == true) {
        this.confirmSN = false;
        alert('S/N Updated');
        this.getDSTestResults();
      }
    })
  }

  announceSortChange(event: Event) {
    console.log(event)
  }

  sortData($event:any) {
    console.log("Sort clicked")
  }

  applySensorFilter($evt: any) {
    const filterValue = ($evt.target as HTMLInputElement).value;
    this.sensorTests.filter = filterValue.trim().toLowerCase();
  }
  applyDeviceFilter($evt: any) {
    const filterValue = ($evt.target as HTMLInputElement).value;
    this.deviceTests.filter = filterValue.trim().toLowerCase();
  }

  goToQCReport() {
    this.qcReporting = true;
  }

  getSNBg(element:any) {
    if(element.serialNumber == null || element.serialNumber == undefined || element.serialNumber == "") {
      return 'orange';
    } else {
      return 'none'
    }
  }

  getTextColor(element:any) {
    if(element.result == '0' || element.result == 'DC 0 ; Tamp 0 ; Act 13 ; OC 9') {
      return 'limegreen';
    } else {
      return 'red';
    }
  }

}
