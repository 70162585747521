<mat-card class="mat-elevation-z8" style="z-index: 1004">
    <div fxLayout="column">
        <h2>Site</h2>
        <div>
            <label>Details for Site:</label>
            <div fxLayout="column">
                <!-- Site Name -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Site Name</mat-label>
                        <input matInput [(ngModel)]="selectedSite.siteName">
                    </mat-form-field>
                </div>
                <!-- Site Key -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Key</mat-label>
                        <input matInput [(ngModel)]="selectedSite.siteKey" minlength="16" maxlength="16">
                    </mat-form-field>
                    <button mat-mini-fab (click)="generateRandomKey()"><mat-icon>refresh</mat-icon></button>
                </div>
                <!-- Customer -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Customer</mat-label>
                        <input matInput [(ngModel)]="selectedSite.customer">
                    </mat-form-field>
                </div>
                <!-- Client DB -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Client DB</mat-label>
                        <!-- <input matInput [(ngModel)]="selectedSite.clientdb"> -->
                        <mat-select [(ngModel)]="selectedSite.clientdb">
                            <mat-option *ngFor="let client of clientList" [value]="client.clientName">{{client.clientName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <button style="margin: 4px;" mat-raised-button color="primary" (click)="addSite()">
                    <mat-icon>save</mat-icon> Create Site
                </button>
                <button style="margin: 4px;" mat-raised-button color="warn" (click)="closing()">
                    <mat-icon>cancel</mat-icon> Cancel
                </button>
            </div>
        </div>
    </div>
</mat-card>