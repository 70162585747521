import { templateJitUrl } from '@angular/compiler';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-token-management',
  templateUrl: './token-management.component.html',
  styleUrls: ['./token-management.component.scss']
})
export class TokenManagementComponent implements OnInit, AfterViewInit {

  tokenList = new MatTableDataSource<any>();
  userTokenList =  new MatTableDataSource<any>();
  displayedColumns1 = ['token', 'siteID', 'delete'];
  displayedColumns2 = ['siteID', 'username','clientdb','funcCode', 'opCode', 'fromDate', 'toDate' , 'delete']

  @ViewChild('paginatorTokens') paginator!: MatPaginator;
  @ViewChild('paginatorUserTokens') paginator1!: MatPaginator;
  @ViewChild(MatSort) sortTokens: MatSort;
  @ViewChild(MatSort) sortUserTokens: MatSort;

  ngAfterViewInit() {
    this.tokenList.paginator = this.paginator;
    this.userTokenList.paginator = this.paginator1;
    this.tokenList.sort = this.sortTokens;
    this.userTokenList.sort = this.sortUserTokens;
  }
  constructor(public webReq: WebRequestsService) {
    // this.tokenList.paginator = this.paginator;
  }

  ngOnInit(): void {

    this.webReq.getAllTokens().subscribe((res: any) => {
      this.tokenList = new MatTableDataSource(res.message);
      this.tokenList.paginator = this.paginator;
      this.tokenList.sort = this.sortTokens;
    });
    this.webReq.getAllUserTokens().subscribe((res:any)=> {
      this.userTokenList = new MatTableDataSource(res.message);
      this.userTokenList.paginator = this.paginator1;
      this.userTokenList.sort = this.sortUserTokens;
    });
    
  }

  deleteToken(id: any) {
    let tempList: any[] = [];
    let found = false;
    console.log(this.tokenList.data.length)
    this.webReq.deleteToken(id).subscribe((res: any) => {
      this.tokenList.data.forEach((token: any) => {
        if (token._id != id) {
          tempList.push(token)
          // console.log("Found token with id: " + id)
        }
      });
      this.tokenList = new MatTableDataSource(tempList);
      this.tokenList.paginator = this.paginator;
      this.tokenList.sort = this.sortTokens;
      console.log(this.tokenList.data.length);
    });
  }

  deleteUserToken(id:any) {
    let tempList: any[] = [];
    let found = false;
    console.log(this.userTokenList.data.length)
    this.webReq.deleteUserToken(id).subscribe((res: any) => {
      this.userTokenList.data.forEach((token: any) => {
        if (token._id != id) {
          tempList.push(token)
        }
      });
      this.userTokenList = new MatTableDataSource(tempList);
      this.userTokenList.paginator = this.paginator1;
      this.userTokenList.sort = this.sortUserTokens;
      console.log(this.userTokenList.data.length);
    });
  }

  sortData($event:any) {
    console.log("Sort clicked")
  }



}
