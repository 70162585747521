<div class="myHeader">
    Client Management
  </div>

  <div class="filter-container">
    <mat-form-field>
      <mat-label>
        Filter <mat-icon>search</mat-icon>
      </mat-label>
      <input matInput [(ngModel)]="filterValue" (keyup)="applyFilter($event)">
    </mat-form-field>
  </div>

<div class="table-container">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" style="width: 100%">
        <ng-container matColumnDef="clientCode">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Client Code </th>
            <td mat-cell *matCellDef="let element"> {{element.clientCode}} </td>
        </ng-container>
       
        <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Client Name </th>
            <td mat-cell *matCellDef="let element"> {{element.clientName}} </td>
        </ng-container>

        <ng-container matColumnDef="authOnKey">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Auth On Key </th>
            <td mat-cell *matCellDef="let element"> 
                <mat-icon style="color: limegreen" *ngIf="element.authOnKey == true">check</mat-icon>
                <mat-icon style="color: red" *ngIf="element.authOnKey != true">cancel</mat-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="authOnConnect">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Auth On Connect </th>
            <td mat-cell *matCellDef="let element"> 
                <mat-icon style="color: limegreen" *ngIf="element.authOnConnect == true">check</mat-icon>
                <mat-icon style="color: red" *ngIf="element.authOnConnect != true">cancel</mat-icon>    
            </td>
        </ng-container>

        <ng-container matColumnDef="totalSites">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Total Sites </th>
            <td mat-cell *matCellDef="let element"> N/I </td>
        </ng-container>

        <ng-container matColumnDef="linkedSites">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Linked Sites </th>
            <td mat-cell *matCellDef="let element"> N/I </td>
        </ng-container>

        <ng-container matColumnDef="totalUsers">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Total Users </th>
            <td mat-cell *matCellDef="let element"> N/I </td>
        </ng-container>


<!-- 
        <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef> Edit </th>
            <td mat-cell *matCellDef="let element"> <mat-icon (click)="selectedSite = element" >edit</mat-icon> </td>
        </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectedClient = row; tempClient= row; totalSitesAndUsers();"></tr>
    </table>


</div>

<button class="btn"
    style="position: absolute; bottom: 7vh; z-index: 3001; color: black; background-color: lime; left: 24px;"
    (click)="addingClient = true"><mat-icon>group_add</mat-icon> Add Client</button>
<mat-paginator #myPaginator [length]="dataSource.data.length" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"
    aria-label="Select page" style="position: absolute; bottom: 6vh; width: 100vw;">
</mat-paginator>


<div class="backdrop" *ngIf="addingClient || selectedClient != null" (click)="selectedClient = null"></div>
<div *ngIf="addingClient"
    style="position: absolute; width:20vw; left: 0; right: 0; margin-left: auto; margin-right:auto; top: 20vh; border:2px solid white; z-index:3011;" cdkDrag>
    <app-add-client (close)="addingClient = false;  getClients();"></app-add-client>
</div>

<div class="editPanel" style="position: absolute; left: 0; right: 0; margin-right: auto; margin-left: auto; top: 20vh; border: 1px solid white; width: 30vw; z-index: 3011; "
*ngIf="selectedClient != null" cdkDrag>
<button mat-mini-fab color="primary" style="position: absolute; top: 4px; right: 4px;"
    (click)="selectedClient = null;">
    <mat-icon>close</mat-icon>
</button>
<div fxLayout="column">
    <h2>Clients</h2>
    <div *ngIf="selectedClient != {}">
        <label>Details for Client:</label>
        <div fxLayout="column" *ngIf="selectedClient != {}">
            <!-- ID -->
            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field appearance="outline">
                    <mat-label>ID</mat-label>
                    <input matInput [(ngModel)]="selectedClient._id" disabled>
                </mat-form-field>
            </div>
            <!-- Client Name -->
            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field appearance="outline">
                    <mat-label>Client Name</mat-label>
                    <input matInput [(ngModel)]="selectedClient.clientName">
                </mat-form-field>
            </div>
            <!-- Client Code -->
            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field appearance="outline">
                    <mat-label>Client Code</mat-label>
                    <input matInput [(ngModel)]="selectedClient.clientCode" disabled>
                </mat-form-field>
            </div>

            <div fxLayout="column" style="border-bottom: 1px solid white">
                <mat-checkbox [(ngModel)]="selectedClient.authOnKey">Authenticate on Keys</mat-checkbox>
                <mat-checkbox [(ngModel)]="selectedClient.authOnConnect">Authenticate on Connect</mat-checkbox>
            </div>

            <div fxLayout="column" fxLayoutAlign="start stretch">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <label>Total Sites for client: </label> <label>{{siteCount}}</label>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <label>Total Sites Linked: </label> <label>{{linkedSiteCount}}</label>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <label>Total Users for client: </label> <label>{{userCount}}</label>
                </div>
            </div>

            <button style="margin: 4px;" mat-raised-button color="warn" (click)="deleteClient()"
                [disabled]="selectedClient != tempClient">
                <mat-icon>delete</mat-icon> Delete Client
            </button>
            <button style="margin: 4px;" mat-raised-button color="primary" (click)="updateClient()"
                >
                <mat-icon>save</mat-icon> Update Client
            </button>
        </div>
    </div>
</div>
</div>