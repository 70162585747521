<mat-card *ngIf="!qcReporting" style="width: 90vw; height:75vh; position: absolute; left: 5vw">
    <mat-tab-group>
        <mat-tab label="Devices">
            <div fxLayoutAlign="center center">
                <mat-form-field appearance="outline">
                    <mat-label>Filter</mat-label>
                    <input matInput (keyup)="applyDeviceFilter($event)">
                </mat-form-field>
            </div>

            <div class="table-container">
                <table mat-table matSort #deviceTable #deviceTableSort="matSort" [dataSource]="deviceTests" style="width: 100%;">
                    <ng-container matColumnDef="serialNumber">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Sort by Serial Number"> S/N </th>
                        <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color':getSNBg(element), 'color': getTextColor(element)}"> {{element.serialNumber}} </td>
                    </ng-container>

                    <ng-container matColumnDef="result">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by result"> Result </th>
                        <td mat-cell *matCellDef="let element" [ngStyle]="{'color': getTextColor(element)}"> {{element.result}} </td>
                    </ng-container>

                    <ng-container matColumnDef="time">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by time"> Time
                        </th>
                        <td mat-cell *matCellDef="let element" [ngStyle]="{'color': getTextColor(element)}"> {{element.time | date:'short'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="version">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by version"> Version
                        </th>
                        <td mat-cell *matCellDef="let element" [ngStyle]="{'color': getTextColor(element)}"> {{element.firmwareVersion}} </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <mat-paginator #paginatorDevices [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
                aria-label="Select page">
            </mat-paginator>
        </mat-tab>
        <mat-tab label="Sensors">
            <div fxLayoutAlign="center center">

                <mat-form-field appearance="outline">
                    <mat-label>Filter</mat-label>
                    <input matInput (keyup)="applySensorFilter($event)">
                </mat-form-field>
            </div>
            <div class="table-container">
                <table mat-table matSort #sensorTable #sensorTableSort="matSort" [dataSource]="sensorTests" (matSortChange)="sortData($event)"
                    style="width: 100%;">
                    <ng-container matColumnDef="serialNumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Serial Number"> S/N </th>
                        <td mat-cell *matCellDef="let element" (click)="scanner = true; tempSensor = element;" [ngStyle]="{'background-color':getSNBg(element), 'color': getTextColor(element)}">
                            {{element.serialNumber}} </td>
                    </ng-container>
                    <ng-container matColumnDef="result">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by result"> Result </th>
                        <td mat-cell *matCellDef="let element" [ngStyle]="{'color': getTextColor(element)}"> {{element.result == '0' ? 'Pass' : 'Fail'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="time">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by time"> Time
                        </th>
                        <td mat-cell *matCellDef="let element" [ngStyle]="{'color': getTextColor(element)}"> {{element.time | date:'short'}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
                </table>
            </div>
            <mat-paginator #paginatorSensors [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
                aria-label="Select page">
            </mat-paginator>
        </mat-tab>
    </mat-tab-group>


</mat-card>

<app-qc-report-download [dsItems]="dsList" [unitItems]="unitList" *ngIf="qcReporting" ></app-qc-report-download>

<!-- <div *ngIf="scanner"
    style="width: 100vw; height: 100vh; overflow:hidden; position:fixed; background-color: rgba(0, 0, 0, 0.4); z-index: 3002 ">
    <mat-card style="position: fixed; left: 8vw; top:25vh; z-index: 3003; width:80vw; height: 60vh;">
        <app-serializer (serialNumberCap)="tempSerial = $event; scanner = false; confirmSN = true;"
            (close)="scanner = false"></app-serializer>
    </mat-card>
</div> -->

<div *ngIf="confirmSN">
    <mat-card
        style="position: fixed; left: 8vw; top:25vh; z-index: 3003; width:80vw; height: auto; border: 1px solid white;"
        fxLayout="column" fxLayoutAlign="space-evenly center">
        <h2>Confirm S/N</h2>
        <label>{{tempSerial}}</label>
        <div>
            <button mat-raised-button color="primary" (click)="saveSN()" style="margin: 4px;"><mat-icon>check</mat-icon>
                Confirm</button>
            <button mat-raised-button color="warn" (click)="confirmSN=false"
                style="margin: 4px;"><mat-icon>cancel</mat-icon> Cancel</button>
        </div>
    </mat-card>
</div>
<!-- <button mat-mini-fab style="position:absolute; left: 4px; bottom: 4px; z-index:2002;" color="primary"
    (click)="goToBT()"><mat-icon>bluetooth</mat-icon></button> -->

    <button mat-mini-fab *ngIf="!qcReporting" style="position:absolute; right: 14px; bottom: 10vh; z-index:2002;" color="primary"
    (click)="goToQCReport()"><mat-icon>download</mat-icon></button>

    <button mat-mini-fab *ngIf="qcReporting" style="position:absolute; right: 14px; bottom: 10vh; z-index:2002;" color="primary"
    (click)="qcReporting = false;"><mat-icon>summarize</mat-icon></button>

    <!-- <button mat-mini-fab style="position:absolute; left: 4px; bottom: 4px; z-index:2002;" color="primary" (click)="goToSummarry()"><mat-icon>summarize</mat-icon></button> -->