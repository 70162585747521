import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
// import { Chart } from 'chart.js';
import { Axis, EChartsOption } from 'echarts';
import { BulkOperationBase, BulkWriteResult } from 'mongodb';
import { WebRequestsService } from 'src/app/services/web-requests.service';


@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.scss']
})
export class HealthComponent implements OnInit {



  from: any = new Date(Date.now() - 1440 * 60000).toISOString().slice(0, 16);
  to: any = new Date(Date.now() + 119 * 60000).toISOString().slice(0, 16);
  showLogsChart: boolean = false;
  showAPIChart: boolean = false;
  showCrashChart: boolean = false;

  reportUrl:any = "none";

  chartOption: EChartsOption = {
    darkMode: true,
    color: ['lightgreen'],
    xAxis: {
      type: 'category',
      data: ['Error', 'Success/Info'],
      // name: 'Response Type',
      // position:'left'
    },
    yAxis: {
      type: 'value',
      name: 'Response Count'
    },
    series: [
      {
        data: [0, 0, 0],
        type: 'bar',
      },
    ],
  };

  apiChartOption: EChartsOption = {
    darkMode: true,
    series: [
      {
        type: 'pie',
        data: []
      }
    ]
  };

  crashChartOption: EChartsOption = {
    xAxis: {
      data: this.showDateSeries()
    },
    yAxis: {},
    series: [
      {
        type: 'scatter',
        data: [220, 182, 191, 234, 290, 330, 310]
      },
      {
        type: 'scatter',
        data: [240, 18, 91, 234, 290, 330, 310]
      }
    ]
  };

  constructor(public webReq: WebRequestsService, private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.getCombinedLogs();
    this.getAPILogs();
    this.getCrashLogs();
    this.showDateSeries();
  }

  getCombinedLogs() {
    this.showLogsChart = false;
    var messages: any = [];
    var messageSet = new Set();
    this.webReq.getCombinedLog(this.from, this.to).subscribe(loggies => {
      // console.log(JSON.stringify(loggies))
      let errorLogs = loggies.filter((x: any) => x.level == 'error' && !x.message.includes('Cannot set headers after they are sent to the client'));
      let infoLogs = loggies.filter((x: any) => x.level == 'info');
      let falseErrorLogs = loggies.filter((x: any) => x.level == 'error' && x.message.includes('Cannot set headers after they are sent to the client'))

      loggies.forEach((logItem: any) => {
        let msgArray = logItem.message.split(' ');
        var tmpMsg = '';
        for (let i = 11; i <= msgArray.length - 1; i++) {
          if (i == msgArray.length - 1) {
            tmpMsg += msgArray[i];
          } else {
            tmpMsg += msgArray[i] + ' '
          }
        }
        messages.push(tmpMsg);
        if (!messageSet.has(tmpMsg)) {
          messageSet.add(tmpMsg);
        }
      });
      // console.log(Array.from(messageSet));
      this.chartOption.darkMode = true;
      this.chartOption.series = [
        {
          barWidth: '50%',
          data: [
            {
              value: errorLogs.length,
              itemStyle: {
                color: 'red',
                shadowColor: '#91cc75',
                borderType: 'dashed',
                opacity: 0.5
              }
            },
            {
              value: infoLogs.length,
              itemStyle: {
                color: 'lightgreen',
                shadowColor: '#91cc75',
                borderType: 'dashed',
                opacity: 0.9
              }
            }
          ],
          type: 'bar',
          emphasis: {
            focus: 'series',
            label: {
              show: true,
              // position: 'right',
              color: 'blue',
              formatter: function(param) {
                // console.log('Formatting label for: ' + JSON.stringify(param.data))
                return JSON.stringify(param.value);
              }
            }
          }
        }
      ]
      this.showLogsChart = true;
      // console.log("Error logs: " + errorLogs.length);
      // console.log("Info logs: " + infoLogs.length);
      // console.log("False Error logs: " + falseErrorLogs.length);
    })
  }

  getAPILogs() {
    this.showAPIChart = false;
    var endpoints: any = [];
    var endpointsSet = new Set();

    this.webReq.getAPILogs(this.from, this.to).subscribe((loggies: any) => {
      // console.log(loggies)
      loggies.forEach((logItem: any) => {
        let logArray = logItem.split(' ');
        let endPoint = logArray[1];
        if (!endpointsSet.has(endPoint)  && endPoint != '/') {
          endpointsSet.add(endPoint);
        }
      });
      Array.from(endpointsSet).forEach((endpoint:any) => {
        if(!endpoint.match(/ \/ /)){
          endpoints.push({ name: endpoint, value: 0 });
        }
      })
      endpoints.forEach((endpoint: any) => {
        loggies.forEach((logItem: any) => {
          if (logItem.includes(endpoint.name)) {
            endpoint.value = endpoint.value + 1;
          }
        });
      })
      // console.log(endpoints);
      this.apiChartOption.series = [
        {
          type: 'pie',
          data: endpoints,
          color: [
            '#191716ff',
            '#e6af2eff',
            '#B2FFA9',
            '#FF4A1C',
            '#3d348bff',
          ],
          emphasis: {
            focus: 'series',
            label: {
              show: true,
              // position: 'right',
              formatter: function(param) {
                // console.log('Formatting label for: ' + JSON.stringify(param.data))
                return param.name + ": " + param.value;
              }
            }
          }
        }
      ]

      this.showAPIChart = true;
    })
  }

  getCrashLogs() {
    this.showCrashChart = false;
    let dateSeries: any = [];
    let chartData:any = [];
    let chartSanData:any = [];
    this.webReq.getCrashLogs().subscribe(loggies => {
      chartData = loggies;
      loggies.forEach((logItem:any) => {
        let messageArr = logItem.message.split(' ')
         let timeStr = messageArr[0] + " " + messageArr[1] + " " + messageArr[2] + " " + messageArr[3] + " " + messageArr[4] + " "+ messageArr[5] + " " + messageArr[6] + " " + messageArr[7] + " " + messageArr[8] + " " + messageArr[9];
         let timeObj = new Date(timeStr);
         if(logItem.message.includes('process exited')) {
          chartSanData.push({level: logItem.level, date: timeObj, message: 'exit'}) 
         } else {
          chartSanData.push({level: logItem.level, date: timeObj, message: 'start'}) 
         }
      });
      let dateArray = this.showDateSeries();
      let tempXValues:any = [];
      let tempXCrashValues: any = [];
      chartSanData.forEach((element:any) => {
        if(element.message == 'start') {
          tempXValues.push(parseFloat((new Date(element.date).getHours().toString() + "." + (new Date(element.date).getMinutes().toString()))));
        } else {
          tempXCrashValues.push(new Date(element.date).getHours());
        }
      });
      let yLabels:any = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24];
      
      this.crashChartOption = {
        legend: {
          data: ['start', 'exit'],
          orient: 'vertical',
          top: 'center',
          right: 10,
          backgroundColor: 'grey'
        },
        xAxis: {
          data: this.showDateSeries()
        },
        yAxis: {
          data: yLabels,
        },
        series: [
          {
            type: 'line',
            data: tempXValues,
            name: 'start',
            emphasis: {
              focus: 'series',
              label: {
                show: true,
                // position: 'right',
                formatter: function(param) {
                  // console.log('Formatting label for: ' + JSON.stringify(param.data))
                  return param.name + ": " + param.value;
                }
              }
            }
          },
          {
            type: 'line',
            data: tempXCrashValues,
            name: 'exit',
            emphasis: {
              focus: 'series',
              label: {
                show: true,
                // position: 'right',
                formatter: function(param) {
                  // console.log('Formatting label for: ' + JSON.stringify(param.data))
                  return param.name + ": " + param.value;
                }
              }
            }
          }
        ]
      };
      this.showCrashChart = true;
      // =============================================

    });
  }

  showDateSeries() {
    let dateArray = new Array();
    let tempFrom:any = this.from;
    let tempTo = this.to;
    // console.log("From date" + tempFrom);
    // console.log("To date" + tempTo);
    while(new Date(tempFrom) <= new Date(tempTo)) {
      dateArray.push(new Date(tempFrom).toLocaleDateString())
      var tempDate = new Date(tempFrom)
      tempFrom = this.addDays(tempFrom, 1);
    }
    // console.log(dateArray)
    return dateArray;
  }

  addDays(tempDate:Date ,days: number) {
    var date = new Date(tempDate.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  


}
