<div class="myHeader">
    Site Management
  </div>
  
  <div class="filter-container">
    <mat-form-field>
      <mat-label>
        Filter <mat-icon>search</mat-icon>
      </mat-label>
      <input matInput [(ngModel)]="filterValue" (keyup)="applyFilter($event)">
    </mat-form-field>
  </div>
  
  <div class="table-container">
    
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" style="width: 100%">
     
      <ng-container matColumnDef="siteID">
        <th mat-header-cell mat-sort-header sortActionDescription="Sort by device ID" *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let element"> {{element.siteID}} </td>
      </ng-container>
  
      <!-- <ng-container matColumnDef="serialnumber">
        <th mat-header-cell mat-sort-header sortActionDescription="Sort by serial number" *matHeaderCellDef> S/N </th>
        <td mat-cell *matCellDef="let element"> {{element.device.serialnumber}} </td>
      </ng-container> -->
  
      <ng-container matColumnDef="siteName">
        <th mat-header-cell mat-sort-header sortActionDescription="Sort by site name" *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.siteName}} </td>
      </ng-container>
  
      <ng-container matColumnDef="customer">
        <th mat-header-cell mat-sort-header sortActionDescription="Sort by customer" *matHeaderCellDef> Customer </th>
        <td mat-cell *matCellDef="let element"> {{element.customer}} </td>
      </ng-container>
  
  
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef> Edit </th>
        <td mat-cell *matCellDef="let element"> <mat-icon (click)="selectedSite = element">edit</mat-icon> </td>
      </ng-container>
  
           <ng-container matColumnDef="clientdb">
        <th mat-header-cell *matHeaderCellDef> Client </th>
        <td mat-cell *matCellDef="let element"> {{element.clientdb}} </td>
      </ng-container>

      <ng-container matColumnDef="linked">
        <th mat-header-cell mat-sort-header sortActionDescription="Sort by linked" *matHeaderCellDef> Linked </th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.device != null && element.device != undefined && element.device.linked == true"
            style="color: lime;">radio_button_checked</mat-icon>
          <mat-icon *ngIf="element.device == null || element.device == undefined || element.device.linked != true"
            style="color: red;">radio_button_checked</mat-icon>
        </td>
      </ng-container>
  
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectedSite = row"></tr>
    </table>
  
  
  </div>
  
  <button class="btn"
    style="position: absolute; bottom: 7vh; z-index: 3001; color: black; background-color: lime; left: 8px;"
    (click)="addingSite = true"><mat-icon>domain_add</mat-icon> Add Site</button>
  <mat-paginator #myPaginator [length]="dataSource.data.length" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"
    aria-label="Select page" style="position: absolute; bottom: 6vh; width: 100vw;">
  </mat-paginator>
  
  <div *ngIf="addingSite"
    style="position: absolute; width:fit-content; left: 0; right:0; margin-left:auto; margin-right: auto; top: 20vh; border:2px solid white; z-index: 3011;"
    cdkDrag>
    <app-add-site (close)="addingSite = false; getSites();" [clientList]="clients"></app-add-site>
  </div>
  
  
  <div class="backdrop" (click)="selectedSite = null; addingSite = false;" *ngIf="selectedSite != null || addingSite"
    style="position: fixed; width: 100vw; height: 100vh; top: 0; z-index: 3004; background-color: rgba(100,100,100,0.5);">
  </div>
  <div class="editPanel" *ngIf="selectedSite != null" cdkDrag>
    <button mat-mini-fab color="primary" style="position: absolute; top: 4px; right: 4px;" (click)="selectedSite = null">
      <mat-icon>close</mat-icon>
    </button>
  
    <div fxLayout="row">
      <div>
        <h2>Sites</h2>
        <div>
          <label>Details for Site:</label>
          <div fxLayout="column" *ngIf="selectedSite != {}">
            <!-- ID -->
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field appearance="outline">
                <mat-label>ID</mat-label>
                <input matInput [(ngModel)]="selectedSite._id" disabled>
              </mat-form-field>
            </div>
            <!-- Site Name -->
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field appearance="outline">
                <mat-label>Site Name</mat-label>
                <input matInput [(ngModel)]="selectedSite.siteName">
              </mat-form-field>
            </div>
            <!-- Site ID -->
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field appearance="outline">
                <mat-label>Site ID</mat-label>
                <input matInput [(ngModel)]="selectedSite.siteID">
              </mat-form-field>
            </div>
            <!-- Customer -->
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field appearance="outline">
                <mat-label>Customer</mat-label>
                <input matInput [(ngModel)]="selectedSite.customer">
              </mat-form-field>
            </div>
            <!-- Location
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field appearance="outline">
                <mat-label>Location</mat-label>
                <input matInput [(ngModel)]="selectedSite.lat + ' ' + selectedSite.lon" disabled>
                <mat-icon (click)="editSiteLocation=true; confirmEditLoc();" matSuffix>edit</mat-icon>
              </mat-form-field>
            </div> -->
  
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field appearance="outline"
                *ngIf="selectedSite.device != null && selectedSite.device != undefined && selectedSite.device.linked == true">
                <mat-label>Linked Device S/N</mat-label>
                <input matInput [(ngModel)]="selectedSite.device.serialnumber">
              </mat-form-field>
              <!-- <a style="color: blue; cursor: pointer;" (click)="isUnlinking = false; manualLinking = true"
                *ngIf="selectedSite.device == null || selectedSite.device == undefined || selectedSite.device.linked == false"><mat-icon>add_link</mat-icon>
                Link Device</a>
              <a style="color: blue; cursor: pointer;" (click)="isUnlinking = true; manualLinking = true"
                *ngIf="selectedSite.device != null && selectedSite.device != undefined && selectedSite.device.linked == true"><mat-icon>add_link</mat-icon>
                Unlink Device</a> -->
            </div>
  
  
  
            <button style="margin: 4px;" mat-raised-button color="warn" (click)="deleteSite()"
              [disabled]="selectedSite.siteID == 10000">
              <mat-icon>delete</mat-icon> Delete site
            </button>
            <button style="margin: 4px;" mat-raised-button color="primary" (click)="updateSite()">
              <mat-icon>save</mat-icon> Update Site
            </button>
          </div>
  
  
        </div>
  
  
  
  
      </div>
      <div fxLayout="column" style="margin: 4px;">
        <h2 style="color: transparent">.</h2>
        <label>Users assigned to site:</label>
        <div class="editPanelMini" style="height: 50%;">
          <div *ngFor="let user of users">
            <div *ngIf="userOfSite(user)">
              <div>{{user.username}}</div>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
  <!-- Ask Manual Link -->
  
  <!-- <div *ngIf="manualLinking"
    style="position: absolute; width:auto; left: 45vw; top: 40vh; width: 20vw; border:2px solid white; z-index: 3012;">
    <mat-card *ngIf="manualLinkingStep == 1">
      <button style="position: absolute; right: 4px; top: 4px" mat-mini-fab
        (click)="manualLinkingStep = 1; manualLinking = false"><mat-icon>close</mat-icon></button>
      <h2>Linking Process</h2>
      <p *ngIf="!isUnlinking">Do you wish to link device automatically?</p>
      <p *ngIf="isUnlinking">Do you wish to unlink device automatically?</p>
      <div fxLayout="row" fxLayoutAlign="space-evenly center">
        <button mat-raised-button color="primary" *ngIf="!isUnlinking"
          (click)="startLinkingDevice()"><mat-icon>bluetooth</mat-icon> Yes</button>
        <button mat-raised-button color="primary" *ngIf="isUnlinking"
          (click)="startUnlinkingDevice()"><mat-icon>bluetooth</mat-icon> Yes</button>
        <button mat-raised-button color="accent" (click)="manualLinkingStep = 2"><mat-icon>bluetooth_disabled</mat-icon>
          No</button>
      </div>
    </mat-card>
  
    <mat-card *ngIf="manualLinkingStep == 2">
      <button style="position: absolute; right: 4px; top: 4px;margin: 4px;" mat-mini-fab
        (click)="manualLinkingStep=1; manualLinking = false"><mat-icon>close</mat-icon></button>
      <h2>Device</h2>
      <div fxLayout="column" style="top: 24px">
        <mat-form-field appearance="outline" *ngIf="!isUnlinking">
          <mat-label>Linked Device S/N</mat-label>
          <input matInput [(ngModel)]="selectedSite.device.serialnumber">
        </mat-form-field>
        <button mat-raised-button (click)="manualLink()" *ngIf="!isUnlinking"
          color="primary"><mat-icon>link</mat-icon>Link</button>
        <button mat-raised-button (click)="manualLink()" *ngIf="isUnlinking"
          color="primary"><mat-icon>link_off</mat-icon>Unlink</button>
      </div>
  
    </mat-card>
  
  </div> -->
  
    <!-- Edit Site Location -->
    <!-- <div *ngIf="editSiteLocation" class="mat-elevation-z16" style="z-index: 3012; position: absolute; left: 0; top: 10vh; right: 0; margin-left: auto; margin-right:auto;" cdkDrag>
      <app-map-selector [site]="selectedSite" (close)="editSiteLocation = false"></app-map-selector>
    </div> -->