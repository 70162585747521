<div fxFlex fxLayoutAlign="center center" fxLayout="column">
    <!-- <app-loader></app-loader> -->
    <mat-card class="mat-elevation-z16">
        <mat-tab-group>

            <mat-tab label="Bluetooth">

                <div fxLayout="column" fxLayoutAlign="center center">
                    <div fxLayout="row" fxLayoutAlign="space-evenly center">
                        <mat-form-field style="width: 40vw;" appearance="outline">
                            <mat-label>From:</mat-label>
                            <input matInput type="date" [(ngModel)]="from">
                        </mat-form-field>
                        <mat-form-field style="width: 40vw;" appearance="outline">
                            <mat-label>To:</mat-label>
                            <input matInput type="date" [(ngModel)]="to">
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-evenly center">
                        <mat-form-field appearance="outline">
                            <mat-label>User:</mat-label>
                            <mat-select [(ngModel)]="user">
                                <mat-option *ngFor="let myuser of allUsers" [value]="myuser.username">
                                    {{myuser.username}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-evenly center">
                        <button mat-raised-button (click)="findLogs(from, to, user);" color="primary">Show Logs</button>
                    </div>

                    <div fxLayoutAlign="start start" style="width: 100%">
                        <mat-form-field appearance="outline">
                            <mat-label>Filter</mat-label>
                            <input matInput [(ngModel)]="searchFilter" (keyup)="applyFilter()">
                        </mat-form-field>
                    </div>
                    
                    <table mat-table [dataSource]="BTdataSource" style="width: 90vw;">
                        <ng-container matColumnDef="user">
                            <th mat-header-cell *matHeaderCellDef> User </th>
                            <td mat-cell *matCellDef="let element"> {{element.user}} </td>
                        </ng-container>

                        <ng-container matColumnDef="site">
                            <th mat-header-cell *matHeaderCellDef> Site </th>
                            <td mat-cell *matCellDef="let element"> {{element.site}} </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef> Action </th>
                            <td mat-cell *matCellDef="let element"> {{element.action}} </td>
                        </ng-container>

                        <ng-container matColumnDef="time">
                            <th mat-header-cell *matHeaderCellDef> Time </th>
                            <td mat-cell *matCellDef="let element"> {{element.time | date:"MM/dd/yy HH:mm:ss"}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    </table>
                    <mat-paginator [length]="allBtLogs.length" [pageSizeOptions]="[5, 10]"></mat-paginator>

                </div>

                <!-- <mat-form-field *ngIf="showLogs" style="width: 85vw;" appearance="fill">
                    <mat-label>BT Log</mat-label>
                    <textarea matInput [value]=logs style="height: 60vh;"></textarea>
                </mat-form-field> -->

            </mat-tab>

            <mat-tab label="Success">

                <mat-form-field style="width: 85vw;" appearance="fill">
                    <mat-label>Success Log</mat-label>
                    <textarea matInput [value]=successLogs style="height: 60vh;"></textarea>
                </mat-form-field>

            </mat-tab>
            <mat-tab label="Error">

                <mat-form-field style="width: 85vw;" appearance="fill">
                    <mat-label>Error Log</mat-label>
                    <textarea matInput [value]=errLogs style="height: 60vh;"></textarea>
                </mat-form-field>

            </mat-tab>
        </mat-tab-group>
    </mat-card>
</div>