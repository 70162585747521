import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-add-site',
  templateUrl: './add-site.component.html',
  styleUrls: ['./add-site.component.scss']
})
export class AddSiteComponent implements OnInit {
  @Output() close = new EventEmitter();
  selectedSite: any = {};
  @Input() clientList: any = [];
  constructor(public webReq: WebRequestsService) { }

  ngOnInit(): void {

  }

  generateRandomKey() {
    this.selectedSite.siteKey = this.getRandomNumber(16);
  }

  getRandomNumber(digit: any) {
    return Math.random().toFixed(digit).split('.')[1];
  }
  // Output: 7170981998871888

  addSite() {
    this.webReq.createSite(this.selectedSite).subscribe(res => {
      alert(res.message);
    });
  }

  closing() {
    this.close.emit();
  }

}
