<div fxLayout="column" fxLayoutAlign="center center">
    <mat-tab-group style="width:90vw">
        <mat-tab label="Tokens">
            <section style="height:70vh; overflow: auto;">

                <table style="width: 100%;" mat-table matSort (matSortChange)="sortData($event)" [dataSource]="tokenList" class="mat-elevation-z8">

                    <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

                    <!-- Token Column -->
                    <ng-container matColumnDef="token">
                        <th mat-header-cell *matHeaderCellDef> Token </th>
                        <td mat-cell *matCellDef="let element"> {{element.token}} </td>
                    </ng-container>

                    <!-- SiteID Column -->
                    <ng-container matColumnDef="siteID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Site ID"> Site ID </th>
                        <td mat-cell *matCellDef="let element"> {{element.siteID}} </td>
                    </ng-container>

                    <!-- Delete Column -->
                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef> Delete </th>
                        <td mat-cell *matCellDef="let element"> <button mat-mini-fab
                                (click)="deleteToken(element._id)"><mat-icon>delete</mat-icon></button> </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns1; sticky: true;"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>

                </table>
                <mat-paginator #paginatorTokens="matPaginator" class="mat-paginator-sticky" [length]="100" [pageSize]="10"
                    [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons="true" aria-label="Select page">

                </mat-paginator>
            </section>

        </mat-tab>

        <mat-tab label="User Tokens">
            <section style="height:70vh; overflow: auto;">

                <table style="width: 100%;" mat-table matSort (matSortChange)="sortData($event)" [dataSource]="userTokenList" class="mat-elevation-z8">

                    <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

                   
                    <ng-container matColumnDef="token">
                        <th mat-header-cell *matHeaderCellDef> Token </th>
                        <td mat-cell *matCellDef="let element"> {{element.token}} </td>
                    </ng-container>

                 
                    <ng-container matColumnDef="funcCode">
                        <th mat-header-cell *matHeaderCellDef> Function </th>
                        <td mat-cell *matCellDef="let element"> {{element.functionCode}} </td>
                    </ng-container>

                    <ng-container matColumnDef="opCode">
                        <th mat-header-cell *matHeaderCellDef> Operation </th>
                        <td mat-cell *matCellDef="let element"> {{element.opCode}} </td>
                    </ng-container>

                  
                    <ng-container matColumnDef="username">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by User"> User </th>
                        <td mat-cell *matCellDef="let element"> {{element.username}} </td>
                    </ng-container>

                    <ng-container matColumnDef="clientdb">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Client"> Client </th>
                        <td mat-cell *matCellDef="let element"> {{element.clientdb}} </td>
                    </ng-container>

                  
                    <ng-container matColumnDef="siteID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Site ID"> Site ID </th>
                        <td mat-cell *matCellDef="let element"> {{element.siteID}} </td>
                    </ng-container>

                    <ng-container matColumnDef="fromDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by from time"> From </th>
                        <td mat-cell *matCellDef="let element"> {{element.fromDate |  date: 'dd/MM/yyyy HH:mm:ss'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="toDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by to time"> To </th>
                        <td mat-cell *matCellDef="let element"> {{element.toDate |  date: 'dd/MM/yyyy HH:mm:ss'}} </td>
                    </ng-container>
              
                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef> Delete </th>
                        <td mat-cell *matCellDef="let element"> <button mat-mini-fab
                                (click)="deleteUserToken(element._id)"><mat-icon>delete</mat-icon></button> </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true;"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns2;" style="margin: 4px;"></tr>

                </table>
                <mat-paginator #paginatorUserTokens="matPaginator" class="mat-paginator-sticky" [length]="100" [pageSize]="10"
                    [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons="true" aria-label="Select page">

                </mat-paginator>
            </section>
        </mat-tab>

        <!-- <mat-tab label="Token IDs">

        </mat-tab> -->
    </mat-tab-group>

</div>