import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss']
})
export class AddClientComponent implements OnInit {

  @Output() close = new EventEmitter();


  selectedClient: any = {};

  constructor(public webReq: WebRequestsService) { }

  ngOnInit(): void {
  }

  closing() {
    this.close.emit();
  }

  addClient() {
    this.webReq.createClient(this.selectedClient).subscribe(res => {
      alert(res);
      if(res.success == true) {
        this.closing();
      }
    });
  }

}
