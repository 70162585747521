<div fxLayout="row" style="height: auto;" fxLayoutAlign="space-evenly center">
    <!-- Clients Column -->

    <div fxLayoutAlign="start start" fxLayout="column">

        <!-- Clients panel -->
        <div>
            <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyClientsFilter($event)" placeholder="Ex. default" value="" #input>
            </mat-form-field>
            <div class="clientList" style="height: 30vh; max-height: 30vh;">
                <div fxLayoutAlign="space-between center" style="margin: 4px;">
                    <label style="font-size: large;">Clients</label>
                    <button mat-mini-fab color="primary" (click)="addingClient = true;"
                        style="position: relative; top: 4px; right: 4px; z-index: 1000;">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
                <mat-divider></mat-divider>
                <!-- <h2>Clients</h2> -->
                <mat-divider></mat-divider>
                <div style="height: 100%; max-height: 25vh; overflow-y: scroll;">
                    <div (click)="selectedClient = client; tempClient = client; totalSitesAndUsers()" class="listItem"
                        *ngFor="let client of filteredClients">
                        {{client.clientName}} ({{client.clientCode}})
                        <mat-divider></mat-divider>
                    </div>
                </div>

            </div>
        </div>
        <!-- Clients edit panel -->
        <div class="editPanel" style="position: absolute; left: 35vw; top: 20vh; border: 1px solid white; width: auto;"
            *ngIf="selectedClient != null" cdkDrag>
            <button mat-mini-fab color="primary" style="position: absolute; top: 4px; right: 4px;"
                (click)="selectedClient = null;">
                <mat-icon>close</mat-icon>
            </button>
            <div fxLayout="column">
                <h2>Clients</h2>
                <div *ngIf="selectedClient != {}">
                    <label>Details for Client:</label>
                    <div fxLayout="column" *ngIf="selectedClient != {}">
                        <!-- ID -->
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-form-field appearance="outline">
                                <mat-label>ID</mat-label>
                                <input matInput [(ngModel)]="selectedClient._id" disabled>
                            </mat-form-field>
                        </div>
                        <!-- Client Name -->
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-form-field appearance="outline">
                                <mat-label>Client Name</mat-label>
                                <input matInput [(ngModel)]="selectedClient.clientName">
                            </mat-form-field>
                        </div>
                        <!-- Client Code -->
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-form-field appearance="outline">
                                <mat-label>Client Code</mat-label>
                                <input matInput [(ngModel)]="selectedClient.clientCode" disabled>
                            </mat-form-field>
                        </div>

                        <div fxLayout="column" style="border-bottom: 1px solid white">
                            <mat-checkbox [(ngModel)]="selectedClient.authOnKey">Authenticate on Keys</mat-checkbox>
                            <mat-checkbox [(ngModel)]="selectedClient.authOnConnect">Authenticate on Connect</mat-checkbox>
                        </div>

                        <div fxLayout="column" fxLayoutAlign="start stretch">
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <label>Total Sites for client: </label> <label>{{siteCount}}</label>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <label>Total Sites Linked: </label> <label>{{linkedSiteCount}}</label>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <label>Total Users for client: </label> <label>{{userCount}}</label>
                            </div>
                        </div>

                        <button style="margin: 4px;" mat-raised-button color="warn" (click)="deleteClient()"
                            [disabled]="selectedClient != tempClient">
                            <mat-icon>delete</mat-icon> Delete Client
                        </button>
                        <button style="margin: 4px;" mat-raised-button color="primary" (click)="updateClient()"
                            [disabled]="selectedClient != tempClient">
                            <mat-icon>save</mat-icon> Update Client
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Subcontractor panel -->
        <div>
            <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyContractorFilter($event)" placeholder="Ex. default" value="" #input>
            </mat-form-field>
            <div class="clientList" style="height: 30vh; max-height: 30vh;">
                <div fxLayoutAlign="space-between center" style="margin: 4px;">
                    <label style="font-size: large;">Sub-Contractors</label>
                    <button mat-mini-fab color="primary" (click)="selectedContractor = null; addingContractor = true;"
                        style="position: relative; top: 4px; right: 4px; z-index: 1000;">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
                <mat-divider></mat-divider>
                <!-- <h2>Contractors</h2> -->
                <mat-divider></mat-divider>
                <div style="height: 100%; max-height: 25vh; overflow-y: scroll;">
                    <div (click)="selectedContractor = contractor; tempContractor = contractor;" class="listItem"
                        *ngFor="let contractor of filteredContractors">
                        {{contractor.name}} ({{contractor.clientdb}})
                        <mat-divider></mat-divider>
                    </div>
                </div>

            </div>
        </div>

        <div cdkDrag *ngIf="selectedContractor != null || addingContractor == true" class="editPanel"
            style="position: absolute; left: 35vw; top: 20vh; border: 1px solid white; width: auto;">
            <app-add-subcontractor [clients]="allClients" [contractor]="tempContractor"
                (close)="selectedContractor = null; addingContractor = false;"></app-add-subcontractor>
        </div>

    </div>

    <!-- Sites Column -->

    <div fxLayoutAlign="start start">
        <div>
            <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applySitesFilter($event)" placeholder="Ex. default" value="" #input>
            </mat-form-field>
            <div class="clientList mat-elevation-z16" style="height:70vh; max-height: 70vh;">
                <div fxLayoutAlign="space-between center" style="margin: 4px;">
                    <label style="font-size: large;">Sites</label>
                    <button mat-mini-fab color="primary" (click)="addingSite = true"
                        style="position: relative; top: 4px; right: 4px; z-index: 1000;">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
                <mat-divider></mat-divider>

                <!-- <h2>Sites</h2> -->
                <div class="mat-elevation-z16 mat-elevation-z16"
                    style="height: 100%; max-height: 65vh; overflow-y: scroll;">
                    <div *ngFor="let client of allClients">
                        <strong style="color: lightgreen;">{{client.clientName}}</strong>
                        <mat-divider></mat-divider>
                        <div (click)="selectedSite = site; tempSite = site; checkSelectedSiteUsers()" class="listItem"
                            *ngFor="let site of filteredSites">
                            <div *ngIf="site.clientdb == client.clientName">
                                <mat-icon style="color:lime; font-size: small;"
                                    *ngIf="site.device?.linked == true">radio_button_checked</mat-icon>
                                <mat-icon style="color:red; font-size: small;"
                                    *ngIf="site.device?.linked == false">radio_button_checked</mat-icon>
                                {{site.siteName}} ({{site.siteID}}) - {{site.clientdb}}

                                <mat-divider></mat-divider>
                            </div>

                        </div>
                    </div>

                    <!-- <mat-divider></mat-divider> -->

                </div>
            </div>
        </div>

        <div class="editPanel" style="position: absolute; left: 35vw; top: 20vh; border: 1px solid white;"
            *ngIf="selectedSite != null" cdkDrag fxLayout="row">
            <button mat-mini-fab color="primary" style="position: absolute; top: 4px; right: 4px;"
                (click)="selectedSite = null">
                <mat-icon>close</mat-icon>
            </button>

            <div fxLayout="column">
                <h2>Sites</h2>
                <div>
                    <label>Details for Site:</label>
                    <div fxLayout="column" *ngIf="selectedSite != {}">
                        <!-- ID -->
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-form-field appearance="outline">
                                <mat-label>ID</mat-label>
                                <input matInput [(ngModel)]="selectedSite._id" disabled>
                            </mat-form-field>
                        </div>
                        <!-- Site Name -->
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-form-field appearance="outline">
                                <mat-label>Site Name</mat-label>
                                <input matInput [(ngModel)]="selectedSite.siteName">
                            </mat-form-field>
                        </div>
                        <!-- Site ID -->
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-form-field appearance="outline">
                                <mat-label>Site ID</mat-label>
                                <input matInput [(ngModel)]="selectedSite.siteID">
                            </mat-form-field>
                        </div>
                        <!-- Site Key -->
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-form-field appearance="outline">
                                <mat-label>Key</mat-label>
                                <input matInput [(ngModel)]="selectedSite.siteKey" disabled>
                            </mat-form-field>
                        </div>
                        <!-- Customer -->
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-form-field appearance="outline">
                                <mat-label>Customer</mat-label>
                                <input matInput [(ngModel)]="selectedSite.customer">
                            </mat-form-field>
                        </div>
                        <!-- Client DB -->
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-form-field appearance="outline">
                                <mat-label>Client DB</mat-label>
                                <input matInput [(ngModel)]="selectedSite.clientdb" disabled>
                            </mat-form-field>
                        </div>

                        <button style="margin: 4px;" mat-raised-button color="warn" (click)="deleteSite()">
                            <mat-icon>delete</mat-icon> Delete site
                        </button>
                        <button style="margin: 4px;" mat-raised-button color="primary" (click)="updateSite()">
                            <mat-icon>save</mat-icon> Update Site
                        </button>
                    </div>
                </div>
            </div>
            <div fxLayout="column" style="margin: 4px;">
                <h2 style="color: transparent">.</h2>
                <label>Users assigned to site:</label>
                <div class="editPanel" style="height: 50%;">
                    <div *ngFor="let user of allUsers">
                        <div *ngIf="userOfSite(user)">
                            <div>{{user.username}}</div>
                            <mat-divider></mat-divider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Users Column -->

    <div fxLayoutAlign="start start">

        <div>
            <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyUsersFilter($event)" placeholder="Ex. default" value="" #input>
            </mat-form-field>
            <div class="clientList mat-elevation-z16" style="height:70vh; max-height: 70vh;">
                <div fxLayoutAlign="space-between center" style="margin: 4px;">
                    <label style="font-size: large;">Users</label>
                    <button mat-mini-fab color="primary" (click)="addingUsers = true"
                        style="position: relative; top: 4px; right: 4px; z-index: 1000;">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
                <mat-divider></mat-divider>
                <!-- <h2>Users</h2> -->
                <div style="height: 100%; max-height:65vh; overflow-y: scroll;">

                    <div *ngFor="let client of allClients">
                        <strong style="color: lightgreen">{{client.clientName}}</strong>
                        <mat-divider></mat-divider>
                        <div (click)="selectedUser = user; tempUser = user; setAllowedSites(user)" class="listItem"
                            *ngFor="let user of filteredUsers">
                            <!-- <div *ngIf="user.clientdb == client.clientName"> -->
                            <div *ngIf="isClientUser(user, client.clientName)">
                                {{user.username}} - {{user.clientdb}}
                                <mat-divider></mat-divider>
                            </div>
                        </div>
                    </div>

                    <div>
                        <strong style="color: red">No Client</strong>
                        <mat-divider></mat-divider>
                        <div (click)="selectedUser = user; tempUser = user; setAllowedSites(user)" class="listItem"
                            *ngFor="let user of allUsers">
                            <div
                                *ngIf="user.clientdb == null || user.clientdb == undefined || user.clientdb.length == 0">
                                <!-- <div > -->
                                {{user.username}}
                                <mat-divider></mat-divider>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>



        <div class="editPanel" cdkDrag
            style="position: absolute; left: 30vw; top: 5vh; border: 1px solid white; z-index: 3000;"
            *ngIf="selectedUser != null && selectedUser != {}">

            <button mat-mini-fab color="primary" style="position: absolute; top: 4px; right: 4px;"
                (click)="selectedUser = null">
                <mat-icon>close</mat-icon>
            </button>
            <div fxLayout="column">
                <h2>Edit User</h2>
                <div fxLayout="row">
                    <div>
                        <label>Details for User:</label>
                        <div fxLayout="column" *ngIf="selectedUser != {}">
                            <!-- ID -->
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <mat-form-field appearance="outline">
                                    <mat-label>ID</mat-label>
                                    <input matInput [(ngModel)]="selectedUser._id" disabled>
                                </mat-form-field>
                            </div>
                            <!-- Email -->
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <mat-form-field appearance="outline">
                                    <mat-label>Email</mat-label>
                                    <input matInput [(ngModel)]="selectedUser.email" disabled>
                                </mat-form-field>
                            </div>
                            <!-- Username -->
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <mat-form-field appearance="outline">
                                    <mat-label>Username</mat-label>
                                    <input matInput [(ngModel)]="selectedUser.username">
                                </mat-form-field>
                            </div>
                            <!-- Name -->
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <mat-form-field appearance="outline">
                                    <mat-label>Name</mat-label>
                                    <input matInput [(ngModel)]="selectedUser.name">
                                </mat-form-field>
                            </div>

                            <!-- Surname -->
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <mat-form-field appearance="outline">
                                    <mat-label>Surname</mat-label>
                                    <input matInput [(ngModel)]="selectedUser.surname">
                                </mat-form-field>
                            </div>

                            <!-- Subcontractor -->
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <mat-form-field appearance="outline">
                                    <mat-label>Sub-Contractor</mat-label>
                                    <mat-select [(ngModel)]="selectedUser.subcontractor">
                                        <mat-option value="None">None</mat-option>
                                        <mat-option *ngFor="let contractor of subcontractors"
                                            [value]="contractor.name">{{contractor.name}}
                                            ({{contractor.clientdb}})</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>


                            <div fxLayout="row">
                                <div style="margin-right: 8px">
                                    <!-- Admin -->
                                    <div fxLayout="row" fxLayoutAlign="start center">
                                        <mat-checkbox [(ngModel)]="selectedUser.isAdmin">Admin</mat-checkbox>
                                    </div>
                                    <!-- sysAdmin -->
                                    <div fxLayout="row" fxLayoutAlign="start center">
                                        <mat-checkbox [(ngModel)]="selectedUser.sysAdmin">System Admin</mat-checkbox>
                                    </div>
                                    <!-- Temp Admin -->
                                    <div fxLayout="row" fxLayoutAlign="start center">
                                        <mat-checkbox [(ngModel)]="selectedUser.tempAdmin">Temp Admin</mat-checkbox>
                                    </div>
                                </div>
    
                                <div>
                                    <!-- Tokens -->
                                    <div fxLayout="row" fxLayoutAlign="start center">
                                        <mat-checkbox [(ngModel)]="selectedUser.generateTokens">Can Generate Tokens
                                        </mat-checkbox>
                                    </div>
                                    <!-- Configure -->
                                    <div fxLayout="row" fxLayoutAlign="start center">
                                        <mat-checkbox [(ngModel)]="selectedUser.canConfigure">Can Configure
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>
                            

                            <!-- Cellphone -->
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <mat-form-field appearance="outline">
                                    <mat-label>Cellphone</mat-label>
                                    <input matInput [(ngModel)]="selectedUser.cellphone">
                                </mat-form-field>
                            </div>

                            <!-- Password -->
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <mat-form-field appearance="outline">
                                    <mat-label>PWD</mat-label>
                                    <input matInput [(ngModel)]="selectedUser.pwd" disabled>
                                </mat-form-field>
                                <button mat-mini-fab style="background-color: lightgreen; color:black"
                                    (click)="resetPassword()">
                                    <mat-icon>restart_alt</mat-icon>
                                </button>
                            </div>

                        </div>
                    </div>
                    <div>
                        <!-- Sites -->
                        <div class="editPanel" style="height: 50vh; max-height: 50vh; overflow-y: scroll;">
                            <div *ngFor="let site of selectedUser.sites">
                                <div>
                                    <div>{{site.siteName}} ({{site.siteID}})</div>
                                    <mat-divider></mat-divider>
                                </div>
                            </div>
                            <button mat-mini-fab
                                style="background-color: lightgreen; color:black; position: absolute; right: 25px; top: 54px;"
                                (click)="addingUserSites = !addingUserSites">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </div>

                        <app-edit-user-sites *ngIf="addingUserSites" [siteList]="allSites"
                            [activeSites]="selectedUser.sites" (close)="addingUserSites = false;"
                            (savedSites)="selectedUser.sites=$event; addingUserSites = false;"></app-edit-user-sites>

                        <!-- Client -->
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-form-field appearance="outline">
                                <mat-label>Clients</mat-label>
                                <mat-chip-list #chipListUserClients>
                                    <mat-chip *ngFor="let client of selectedUser.clientdb"
                                        (removed)="removeClientFromUser(client)">{{client}}<button matChipRemove
                                            fxLayout="row" fxLayoutAlign="center center"
                                            style="background: transparent; border: transparent">
                                            <mat-icon>cancel</mat-icon>
                                        </button></mat-chip>
                                </mat-chip-list>
                                <input placeholder="" [matChipInputFor]="chipListUserClients">
                            </mat-form-field>
                            <mat-form-field style="width: auto;" *ngIf="addingUserClients" appearance="outline">
                                <mat-select [(ngModel)]="selectedUser.clientdb" multiple>
                                    <mat-option *ngFor="let client of allClients" [value]="client.clientName">
                                        {{client.clientName}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button mat-mini-fab style="background-color: lightgreen; color:black"
                                (click)="addingUserClients = !addingUserClients">
                                <mat-icon>add</mat-icon>
                            </button>

                        </div>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutAlign="space-around center">
                    <button style="margin: 4px;" mat-raised-button color="warn" (click)="deleteUser()">
                        <mat-icon>delete</mat-icon> Delete User
                    </button>
                    <button style="margin: 4px;" mat-raised-button color="primary" (click)="updateUser()">
                        <mat-icon>save</mat-icon> Update User
                    </button>
                </div>


            </div>
        </div>
    </div>

</div>

<div *ngIf="addingClient"
    style="position: absolute; width:auto; left: 35vw; top: 20vh; border:2px solid white; z-index:1005;" cdkDrag>
    <app-add-client (close)="addingClient = false;"></app-add-client>
</div>
<div *ngIf="addingSite"
    style="position: absolute; width:auto; left: 35vw; top: 20vh; border:2px solid white; z-index:1005;" cdkDrag>
    <app-add-site (close)="addingSite = false;" [clientList]="allClients"></app-add-site>
</div>
<div *ngIf="addingUsers"
    style="position: absolute; width:auto; left: 40vw; top: 10vh; border:2px solid white; z-index:1005;" cdkDrag>
    <app-add-user (close)="addingUsers = false; getAllUsers();" [clientList]="allClients"></app-add-user>
</div>

<div id="myOverlay"
    *ngIf="addingClient || addingSite || addingUsers || selectedClient != null || selectedSite != null || (selectedUser != null && selectedUser != {})">
</div>