<!-- <div fxFlex fxLayoutAlign="center center" fxLayout="column"> -->
<div fxFlex fxLayoutAlign="center center" fxLayout="column">
    <mat-card fxLayout="column" fxLayoutAlign="space-evenly center" class="mat-elevation-z8"
        style="position: absolute; top:12vh; left:1vw; width: 20vw; height: 25vh; border: 1px solid white">
        <mat-form-field style="width: auto;" appearance="outline">
            <mat-label>From:</mat-label>
            <input matInput type="datetime-local" [(ngModel)]="from">
        </mat-form-field>
        <mat-form-field style="width: auto;" appearance="outline">
            <mat-label>To:</mat-label>
            <input matInput type="datetime-local" [(ngModel)]="to">
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="getCombinedLogs(); getAPILogs(); getCrashLogs();">Check Logs</button>
    </mat-card>

    <mat-card cdkDrag style="position:absolute; left: 22vw; top: 12vh; border: 1px solid white; padding: 0px; width: 20vw; height: 25vh;">
        <mat-card-header>API response statistics</mat-card-header>
        <div echarts [options]="chartOption" class="demo-chart" style="width: 350px; height: 22vh" *ngIf="showLogsChart"></div>
    </mat-card>
    
    <mat-card cdkDrag style="position:absolute; left: 1vw; top: 40vh; border: 1px solid white; padding: 0px; width: auto; height: auto;">
        <mat-card-header>API response statistics</mat-card-header>

        <div echarts [options]="apiChartOption" class="demo-chart" style="width: 42vw; height: 30vh; z-index: 2002" *ngIf="showAPIChart"></div>
    </mat-card>

    <!-- <mat-card cdkDrag style="position:absolute; left: 45vw; top: 40vh; border: 1px solid white; padding: 0px; width: auto; height: 25vh;">
        <mat-card-header>Crashlytics</mat-card-header>
        <div echarts [options]="crashChartOption" class="demo-chart" style="width: 40vw; height: 25vh; z-index: 2002;" *ngIf="showCrashChart"></div>
    </mat-card> -->
   
</div>