import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-site-management',
  templateUrl: './site-management.component.html',
  styleUrls: ['./site-management.component.scss']
})
export class SiteManagementComponent implements OnInit {

  filterValue="";
  displayedColumns: string[] = ['siteID', 'siteName', 'customer','linked', 'clientdb'];
  dataSource = new  MatTableDataSource();
  sites = [];
  sitesFiltered = [];
  addingSite = false;
  selectedSite = null;
  manualLinking: boolean = false;
  manualLinkingStep = 1;
  isUnlinking: boolean = false;
  isConfig: boolean = false;
  deviceState = 0;
  listenType = 0; // Listen types: [0:normal,1:version,2:config]
  confSubject: Subject<any> = new Subject();
  isConnected: boolean = false;
  users = [];
  editSiteLocation = false;
  clients = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(public webReq: WebRequestsService) { }

  ngOnInit(): void {
    this.getSites();
    this.getUsers();
    this.getClients();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.data = this.sites.filter((x: any) => x.handedOver != true && (
    x.siteName.toLowerCase().includes(filterValue.toLowerCase()) || 
    x.siteID.toString().includes(filterValue.toLowerCase())
    ));
  }

  getClients() {
    this.webReq.getAllClients().subscribe(clients => {
      this.clients = clients
    })
  }

  getUsers() {
    this.webReq.getAllUsers().subscribe(users => {
      this.users = users;
    })  
  }

  getSites() {
    let tempSites = [];
    this.webReq.getAllSites().subscribe(data => {
      data.forEach(site => {
        if(site.handedOver != true) {
          tempSites.push(site);
        }
      });
      this.sites = tempSites;
      this.dataSource.data = this.sites;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, err => {
      // this.notify.openMessage('Failed to get sites', 'error')
    });
  }

  deleteSite() {
    this.selectedSite.id = this.selectedSite._id;
    this.webReq.deleteSite(this.selectedSite).subscribe(result => {
      // this.getSites();
      // this.isDeleting = false;
      // console.log(this.selectedSite)
      this.selectedSite = null;
      this.getSites();
      // this.notify.openMessage(result.message, result.success == true ? 'success' : 'error', 3000);
      alert('Site Deleted');
    });
  }

  updateSite() {
    this.webReq.updateSite(this.selectedSite).subscribe(result => {
      // this.getSites();
      this.selectedSite = null;
      alert('Site updated')
      // this.notify.openMessage(result.message, result.success==true?'success':'error', 3000);
      this.getSites();
    })
  }




  userOfSite(user:any) {
    if(user.sites != null) {
      if (user.sites.filter((x:any) => x._id == this.selectedSite._id).length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
