import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebRequestsService {

  // public url = 'mongodb://192.168.3.120?retryWrites=true&writeConcern=majority';
  // public client = new MongoClient(this.url);
  // public dbName = 'Prism_Mobile'

  constructor(public http: HttpClient) { }

  sendTestResults(obj:any) {
    return this.http.post<any>(environment.baseUrl + '/device_test_result_store', obj);
  }
  sendDSTestResults(obj:any) {
    return this.http.post<any>(environment.baseUrl + '/sensor_test_result_store', obj);
  }

  getTestResults() {
    return this.http.post<any>(environment.baseUrl + '/device_test_result_get', {});
  }

  getDSTestResults() {
    return this.http.post<any>(environment.baseUrl + '/sensor_test_result_get', {});
  }

  updateDSResultsSN(obj: any) {
    return this.http.post<any>(environment.baseUrl + '/sensor_test_result_update', obj);
  }
  
  getAllSites() {
    return this.http.post<any>(environment.baseUrl + "/global_get_sites", {});
  }

  getAllClients() {
    return this.http.post<any>(environment.baseUrl + "/global_get_clients", {});
  }

  getAllUsers() {
    return this.http.post<any>(environment.baseUrl + "/global_get_users", {});
  }
  
  getAllSubcontractors() {
    return this.http.post<any>(environment.baseUrl + "/global_get_subcontractors", {});
  }

  createUser(user:any) {
    return this.http.post<any>(environment.baseUrl + "/global_add_user", user);
  }
  updateUser(user:any) {
    return this.http.post<any>(environment.baseUrl + "/global_update_user", user);
  }
  deleteUser(user:any) {
    return this.http.post<any>(environment.baseUrl + "/global_delete_user", user);
  }


  getBTLog(from: any, to: any, user: any) {
    return this.http.post<any>(environment.baseUrl + '/get_bt_log', { 'from': from, 'to': to, 'user': user });
  }

  getCombinedLog(from: any, to: any) {
    return this.http.post<any>(environment.baseUrl + '/get_combined_logs', { 'from': from, 'to': to });
  }
  getAPILogs(from: any, to: any) {
    return this.http.post<any>(environment.baseUrl + '/get_api_logs', { 'from': from, 'to': to });
  }
  getCrashLogs() {
    return this.http.post<any>(environment.baseUrl + '/get_crash_logs', {});
  }
  createSite(siteDetails:any) {
    return this.http.post<any>(environment.baseUrl + "/add_site", { "sitename": siteDetails.siteName, "siteKey": siteDetails.siteKey, "clientdb": siteDetails.clientdb,"customer":siteDetails.customer });
  }
  updateSite(site:any) {
    return this.http.post<any>(environment.baseUrl + "/rename_site", { site: site });
  }

  createClient(client:any) {
    return this.http.post<any>(environment.baseUrl + "/global_add_client", { "clientName": client.clientName, "clientCode": client.clientCode });
  }

  updateClient(client:any) {
    return this.http.post<any>(environment.baseUrl + "/global_update_client", client);
  }
  deleteClient(client:any) {
    return this.http.post<any>(environment.baseUrl + "/global_delete_client", client);
  }
  deleteSite(site:any) {
    return this.http.post<any>(environment.baseUrl + "/delete_site", site);
  }

  resetPassword(user:any) {
    return this.http.post<any>(environment.baseUrl + "/password_reset", user);
  }

  login(user:any) {
    return this.http.post<any>(environment.baseUrl + "/login", user);
  }

  getAllTokens() {
    return this.http.post<any>(environment.baseUrl + "/get_all_tokens", {});
  }
  deleteToken(id:any) {
    return this.http.post<any>(environment.baseUrl + "/delete_token_id", {tokenID:id});
  }
  getAllUserTokens() {
    return this.http.post<any>(environment.baseUrl + "/get_all_user_tokens", {});
  }
  deleteUserToken(id:any) {
    return this.http.post<any>(environment.baseUrl + "/delete_user_token_id", {tokenID:id});
  }

  updateContractor(contractor) {
    // let clientdb = localStorage.getItem('clientdb');
    // contractor.clientdb = clientdb;
    return this.http.post<any>(environment.baseUrl + '/update_subcontractor', contractor);
  }

  deleteContractor(contractor) {
    // let clientdb = localStorage.getItem('clientdb');
    // contractor.clientdb = clientdb;
    return this.http.post<any>(environment.baseUrl + '/delete_subcontractor', contractor);
  }

  addContractor(contractor) {
    // let clientdb = localStorage.getItem('clientdb');
    // contractor.clientdb = clientdb;
    return this.http.post<any>(environment.baseUrl + '/add_subcontractor', contractor);
  }
}
