import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit(): void {
  }

  navToDashboard() {
    this.router.navigate(['/dashboard'])
  }

  navToSites() {
    this.router.navigate(['/site-management'])
  }

  navToUsers() {
    this.router.navigate(['/user-management'])
  }

  navToVKeys() {
    this.router.navigate(['/token-management'])
  }

  navToClients() {
    this.router.navigate(['/client-management'])
  }

  navToAlarms() {
    this.router.navigate(['/alarms'])
  }

  navToChangePwd() {
    this.router.navigate(['/change-password'])
  }

  navToSubcontractors() {
    this.router.navigate(['/subcontractor-management'])
  }

  navToHandovers() {
    this.router.navigate(['/handover-management'])
  }

  navToHealth() {
    this.router.navigate(['/health'])
  }

  navToReporting() {
    this.router.navigate(['/reporting'])
  }

  navToLogs() {
    this.router.navigate(['/audit']);
  }
}
