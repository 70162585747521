import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-subcontractor-management',
  templateUrl: './subcontractor-management.component.html',
  styleUrls: ['./subcontractor-management.component.scss']
})
export class SubcontractorManagementComponent implements OnInit {

  displayedColumns: string[] = ['name', 'contactEmail', 'contactNumber', 'address', 'clientdb'];
  dataSource = new  MatTableDataSource();
  addingSubcontractor = false;
  subcontractors = [];
  selectedSubcontractor = null;
  filterValue = "";
  clients = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  
  constructor(public webReq: WebRequestsService) { }

  ngOnInit(): void {
    this.getSubcontractors();
    this.getClients();
  }

  getClients() {
    this.webReq.getAllClients().subscribe(clients => {
      this.clients= clients;
    })
  }
  getSubcontractors() {
    this.webReq.getAllSubcontractors().subscribe(sc => {
      this.subcontractors = sc;
      this.dataSource.data = this.subcontractors;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.data = this.subcontractors.filter((x: any) => 
    x.name.toLowerCase().includes(filterValue.toLowerCase()) ||
    x.contactNumber.toLowerCase().includes(filterValue.toLowerCase()) ||
    x.address.toLowerCase().includes(filterValue.toLowerCase()) || 
    x.contactEmail.toString().includes(filterValue.toLowerCase())
    );
  }



}
