import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-client-management',
  templateUrl: './client-management.component.html',
  styleUrls: ['./client-management.component.scss']
})
export class ClientManagementComponent implements OnInit {

  selectedClient = null;
  clients = []
  filterValue = "";
  filteredClients = [];
  tempClient = null;
  siteCount: any = 0;
  linkedSiteCount: any = 0;
  userCount: any = 0;
  allSites = [];
  allUsers = [];
  addingClient = false;


  displayedColumns: string[] = ['clientCode', 'name', 'authOnKey', 'authOnConnect'];
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(public webReq: WebRequestsService) { }

  ngOnInit(): void {
    this.getSites();
    this.getUsers();
    this.getClients();
  }

  getClients() {
    this.webReq.getAllClients().subscribe(clients => {
      this.clients = clients;
      this.filteredClients = clients;
      this.dataSource.data = this.filteredClients;
      this.dataSource.sort = this.sort;
    })
  }

  getSites() {
    this.webReq.getAllSites().subscribe(sites => {
      this.allSites = sites;
    })
  }
  getUsers() {
    this.webReq.getAllUsers().subscribe(users => {
      this.allUsers = users;
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.data = this.filteredClients.filter((x: any) =>
      x.name.toLowerCase().includes(filterValue.toLowerCase()) ||
      x.contactNumber.toLowerCase().includes(filterValue.toLowerCase()) ||
      x.address.toLowerCase().includes(filterValue.toLowerCase()) ||
      x.contactEmail.toString().includes(filterValue.toLowerCase())
    );
  }


  updateClient() { // Only able to update clientName
    this.webReq.updateClient(this.selectedClient).subscribe(res => {
      alert(res.message);
      this.webReq.getAllClients().subscribe(clients => {
        this.clients = clients;
        this.selectedClient = null;
      });
    });
  }

  deleteClient() {
    this.webReq.deleteClient(this.selectedClient).subscribe(res => {
      alert(res.message);
      this.getClients();
      this.selectedClient = null;
    })
  }

  totalSitesAndUsers() {
    this.siteCount = 0;
    this.userCount = 0;
    this.linkedSiteCount = 0;
    console.log(this.selectedClient)
    this.allSites.forEach((site: any) => {
      if (site.clientdb == this.selectedClient.clientName) {
        this.siteCount++;
        if (site.device.linked == true) {
          this.linkedSiteCount++;
        }
      }
    });
    this.allUsers.forEach((user: any) => {
      user.clientdb.forEach((client: any) => {
        if (client == this.selectedClient.clientName) {
          this.userCount++;
        }
      });
    });

  }


}
