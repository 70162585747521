<mat-card cdkDrag class="mat-elevation-z8" style="position: fixed; top:25vh; left:25vw; z-index: 1005; width: 30vw; height:50vh; border: 1px solid white;" fxLayout="column" fxLayoutAlign="space-evenly center">
    <button mat-mini-fab color="primary" style="position: absolute; right:4px; top: 4px;" (click)="closing()"><mat-icon>close</mat-icon></button>
    <div fxLayout="column">
        <h2>Edit user sites</h2>
        <div fxLayout="row" fxLayoutAlign="space-evenly start">

            <div>
                <label>Acitve Sites</label>
                <div class="editPanel">
                    <div *ngFor="let site of activeSites" class="listItem">
                        <mat-checkbox (change)="checkCurrentSite(site, $event.checked)"></mat-checkbox>
                        {{site.siteName}}
                        <mat-divider style="position: relative;"></mat-divider>
                    </div>
                </div>
            </div>

            <div fxLayout="column">
                <button mat-mini-fab style="margin: 12px;" (click)="removeActiveSites()"><mat-icon>arrow_forward</mat-icon></button>
                <button mat-mini-fab style="margin: 12px;" (click)="addActiveSites()"><mat-icon>arrow_back</mat-icon></button>    
            </div>
            
            <div>
                <label>Inactive Sites</label>
                <div class="editPanel">
                    <div *ngFor="let site of siteList" class="listItem">
                        <mat-checkbox (change)="checkNonCurrentSite(site, $event.checked)"></mat-checkbox>
                        {{site.siteName}}
                        <mat-divider style="position: relative;"></mat-divider>
                    </div>
                </div>
            </div>

        </div>
    </div>
        <button mat-raised-button color="primary" (click)="save()" style="width: 50%;"><mat-icon style="color:lime" >checkmark</mat-icon> Done</button>
</mat-card>