import { Component, OnInit } from '@angular/core';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-clients-sites-users',
  templateUrl: './clients-sites-users.component.html',
  styleUrls: ['./clients-sites-users.component.scss']
})
export class ClientsSitesUsersComponent implements OnInit {

  allClients: any = [];
  filteredClients: any = [];
  allSites: any = [];
  filteredSites: any = [];
  allUsers: any = [];
  filteredUsers: any = [];
  subcontractors: any = [];
  filteredContractors: any = [];
  

  allowedSites: any = [];

  selectedClient: any = {};
  tempClient: any = {};
  selectedSite: any = {};
  tempSite: any = {};
  selectedUser: any = {};
  tempUser: any = {};
  selectedContractor: any = null;
  tempContractor: any = null;

  addingClient: boolean = false;
  addingSite: boolean = false;
  addingUsers: boolean = false;
  addingUserClients: boolean = false;
  addingUserSites: boolean = false;
  addingContractor: boolean = false;

  siteCount: any = 0;
  linkedSiteCount: any =0;
  userCount:any = 0;

  constructor(public webReq: WebRequestsService) { }

  ngOnInit(): void {
    this.selectedClient = null;
    this.selectedSite = null;
    this.selectedUser = null;
    
    this.webReq.getAllClients().subscribe(clients => {
      this.allClients = clients;
      this.filteredClients = this.allClients;
      this.webReq.getAllSites().subscribe(sites => {
        this.allSites = sites;
        this.filteredSites = this.allSites;
        this.webReq.getAllUsers().subscribe(users => {
          this.allUsers = users;
          this.filteredUsers = this.allUsers;
          this.webReq.getAllSubcontractors().subscribe(contractors => {
            this.subcontractors = contractors;
            this.filteredContractors = this.subcontractors;
          });
        });
      });
    });
  }

  applyClientsFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredClients = this.allClients.filter((x:any) => x.clientName.toLowerCase().includes(filterValue));
    if(filterValue == "") {
      this.filteredClients = this.allClients;
    }
  }

  applySitesFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredSites = this.allSites.filter((x:any) => x.siteName.toLowerCase().includes(filterValue) || x.siteID.toString().includes(filterValue));
    if(filterValue == "") {
      this.filteredSites = this.allSites;
    }
  }

  applyUsersFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredUsers = this.allUsers.filter((x:any) => x.username.toLowerCase().includes(filterValue));
    if(filterValue == "") {
      this.filteredUsers = this.allUsers;
    }
  }

  applyContractorFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredContractors = this.subcontractors.filter((x:any) => x.name.toLowerCase().includes(filterValue));
    if(filterValue == "") {
      this.filteredContractors = this.subcontractors;
    }
  }

  logDetails(client:any) {
    console.log(JSON.stringify(client));
  }

  removeSiteFromUser(site:any) {
    const index = this.selectedUser.sites.indexOf(site);
    if(index>=0) {
      this.selectedUser.sites.splice(index, 1);
    }
  }

  removeClientFromUser(client:any) {
    const index = this.selectedUser.clientdb.indexOf(client);
    if(index>=0) {
      this.selectedUser.clientdb.splice(index, 1);
    }
  }

  setAllowedSites(user:any) {
    this.allowedSites = [];
    console.log('setting allowed sites')
    user.clientdb.forEach((client:any )=> {
      var tempList = this.allSites.filter((x:any)=>x.clientdb == client);
      tempList.forEach((site:any) => {
        this.allowedSites.push(site); 
        console.log('Added allowed site: ' + JSON.stringify(site))       
      });
    });
  }

  deleteClient() {
    this.webReq.deleteClient(this.selectedClient).subscribe(res => {
      alert(res.message);
      this.webReq.getAllClients().subscribe(clients => {
        this.allClients = clients;
        this.selectedClient = {};
      })
    })
  }

  deleteSite() {
    this.webReq.deleteSite(this.selectedSite).subscribe((res:any) => {
      alert(res.message);
      this.webReq.getAllSites().subscribe(sites => {
        this.allSites = sites;
        this.selectedSite = {};
      });
    })
  }

  updateClient() { // Only able to update clientName
    this.webReq.updateClient(this.selectedClient).subscribe(res => {
      alert(res.message);
      this.webReq.getAllClients().subscribe(clients=> {
        this.allClients = clients;
        this.selectedClient = null;
      });
    });
  }

  updateSite() { // Should only be able to update encryption key and name of site
    this.webReq.updateSite(this.selectedSite).subscribe(res => {
      alert(res.message);
      this.webReq.getAllSites().subscribe(sites => {
        this.allSites = sites;
        this.selectedSite = null;
      });
    })
  }

  updateUser() { // Should be able to update username, cellphone, clients, sites
    this.webReq.updateUser(this.selectedUser).subscribe(res => {
      alert(res.message);
      this.webReq.getAllUsers().subscribe(users => {
        this.allUsers = users;
        this.selectedUser = null;
        this.addingUserSites = false;
        this.addingUserClients = false;
      })
    })
  }

  deleteUser() {
    this.webReq.deleteUser(this.selectedUser).subscribe(res => {
      alert(res.message);
      this.webReq.getAllUsers().subscribe(users => {
        this.allUsers = users;
        this.selectedUser = {};
        this.addingUserSites = false;
        this.addingUserClients = false;
      })
    })
  }

  resetPassword() {
    this.webReq.resetPassword(this.selectedUser).subscribe(res => {
      alert(res.message);
      this.webReq.getAllUsers().subscribe(users => {
        this.allUsers = users;
        this.selectedUser = {};
        this.addingUserSites = false;
        this.addingUserClients = false;
      })
    })
  }

  userOfSite(user:any) {
    if(user.sites != null) {
      if (user.sites.filter((x:any) => x._id == this.selectedSite._id).length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  checkSelectedSiteUsers() {
    var siteUsers = [];
    console.log("Selected site ID: " + this.selectedSite._id)
    this.allUsers.forEach((user:any) => {
      if(user.sites != null) {
        var filteredSites = user.sites.filter((x:any)=> x._id == this.selectedSite._id)
        console.log("Filtered sites for " + user.username + ": " + JSON.stringify(filteredSites))  
      }
      
    });
  }

  isClientUser(user:any,client:any) {
    let result = false;
    if(user.clientdb != null && user.clientdb != undefined) {
      user.clientdb.forEach((clientName:any) => {
        if(clientName == client) {
          result = true;
        } 
      });
      return result;
    } else {
      return false;
    }
    
  }

  getAllUsers() {
    this.webReq.getAllUsers().subscribe(users => {
      this.allUsers = users;
    });
  }

  totalSitesAndUsers() {
    this.siteCount = 0;
    this.userCount = 0;
    this.linkedSiteCount = 0;
    console.log(this.selectedClient)
    this.allSites.forEach((site:any) => {
      if(site.clientdb == this.selectedClient.clientName) {
        this.siteCount++;
        if(site.device.linked == true) {
          this.linkedSiteCount++;
        }
      }
    });
    this.allUsers.forEach((user:any) => {
      user.clientdb.forEach((client:any) => {
        if(client == this.selectedClient.clientName) {
          this.userCount++;
        }
      });
    });

  }

  getAllSubcontractors() {
    this.webReq.getAllSubcontractors().subscribe(contractors => {
      this.subcontractors = contractors;
      this.filteredContractors = this.subcontractors;
    });
  }

}
