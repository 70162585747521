import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  @Output() close = new EventEmitter();

  @Input() clientList: any = [];

  selectedUser: any = {};
  addingUserClients: boolean = false;

  constructor(public webReq: WebRequestsService) { }

  ngOnInit(): void {
  }

  closing() {
    this.close.emit();
  }

  removeSiteFromUser(site:any) {
    const index = this.selectedUser.sites.indexOf(site);
    if(index>=0) {
      this.selectedUser.sites.splice(index, 1);
    }
  }

  removeClientFromUser(client:any) {
    const index = this.selectedUser.clientdb.indexOf(client);
    if(index>=0) {
      this.selectedUser.clientdb.splice(index, 1);
    }
  }

  addUser() {
    this.webReq.createUser(this.selectedUser).subscribe((res:any) => {
      alert(res.message);
      this.closing();
    });
  }

}
