<div fxLayout="column" style="height: 100vh; " fxLayoutAlign="center center">
    <mat-card fxLayout="column">
        <h1>Login</h1>
        <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput placeholder="email" [(ngModel)]="email">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input matInput type="password" [(ngModel)]="password">
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="login()">Login</button>
    </mat-card>
</div>