import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { WebRequestsService } from '../services/web-requests.service';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss']
})
export class AuditComponent implements OnInit {
  allClients: any = [];
  allSites: any = [];
  allUsers: any = [];
  allBtLogs: any =[];

  selectedClient: any = {};
  selectedSite: any = {};
  selectedUser: any = {};

  BTdataSource = new MatTableDataSource<any>(this.allBtLogs);
  displayedColumns: string[] = ['user', 'action', 'time', 'site'];
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  public logs:any;
  public btLogs:any;
  public errLogs:any;
  public successLogs:any;
  public showLogs:boolean = false;
  public users: any = [];
  public from: any;
  public to:any;
  public user:string = "";
  public searchFilter: string = "";
  public allLogs:any;

  constructor(public webReq: WebRequestsService) { }

  ngAfterViewInit() {
    this.BTdataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.webReq.getAllClients().subscribe(clients => {
      this.allClients = clients;
      this.webReq.getAllSites().subscribe(sites => {
        this.allSites = sites;
        this.webReq.getAllUsers().subscribe(users => {
          this.allUsers = users;
        });
      });
    });
  }

  findLogsOld(from:any,to:any,user:any) {
    this.webReq.getBTLog(from,to,user).subscribe(loggies => {
      console.log(loggies);
      let tempLogs:any[] = [];
      loggies.forEach((mylog:any) => {
        tempLogs.push(mylog.user+", " + mylog.action + ", " + mylog.time.toString('YYYY/MM/DD') + "\n");
      });
      this.showLogs = true;
      this.logs = tempLogs;
      this.BTdataSource.data = loggies;
      this.allLogs = loggies;
      this.BTdataSource.filterPredicate = (data: any, filter: string) => {
        return data.action.toLowerCase().includes(filter.toLowerCase());
       };
    },err => {
      console.log(err);
    });
  }

  findLogs(from: any, to: any, user: any) {
    this.webReq.getBTLog(from, to, user).subscribe((loggies:any) => {
      console.log(loggies);
      let tempLogs: any[] = [];
      loggies.forEach((mylog: any) => {
        tempLogs.push(mylog.user + ", " + mylog.action + ", " + mylog.time.toString('YYYY/MM/DD') + "\n");
      });
      // this.showLogs = true;
      this.logs = tempLogs;
      this.allBtLogs = tempLogs
      this.BTdataSource.data = loggies;
    }, err => {
      console.log(err);
    });
  }

  filterResults() {
    this.BTdataSource.data = this.BTdataSource.data.filter(x => x.action.includes(this.searchFilter));
  }
  applyFilter() {
    // let filterValue = this.searchFilter.trim(); // Remove whitespace
    // filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches    
    this.BTdataSource.filter = this.searchFilter;
  }
}
