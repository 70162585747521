import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';

import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTreeModule} from '@angular/material/tree';
import {DragDropModule} from '@angular/cdk/drag-drop';

import { FormsModule } from '@angular/forms';
import { ClientsSitesUsersComponent } from './components/clients-sites-users/clients-sites-users.component';
import { AuditComponent } from './audit/audit.component';
import { AddClientComponent } from './popups/add-client/add-client.component';
import { AddSiteComponent } from './popups/add-site/add-site.component';
import { AddUserComponent } from './popups/add-user/add-user.component';
import { LoginComponent } from './components/login/login.component';
// import { ConfirmationComponent } from './popups/confirmation/confirmation.component';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthinterceptorInterceptor } from './interceptors/authinterceptor.interceptor';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { HealthComponent } from './components/health/health.component';

import { NgxEchartsModule } from 'ngx-echarts';
import { ReportingComponent } from './components/reporting/reporting.component';
import { EditUserSitesComponent } from './popups/edit-user-sites/edit-user-sites.component';
import { TokenManagementComponent } from './components/token-management/token-management.component';
import { MatSortModule } from '@angular/material/sort';
import { AddSubcontractorComponent } from './popups/add-subcontractor/add-subcontractor.component';
import { QcReportComponent } from './components/qc-report/qc-report.component';
import { QcReportDownloadComponent } from './components/qc-report-download/qc-report-download.component';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { SiteManagementComponent } from './components/site-management/site-management.component';
import { SubcontractorManagementComponent } from './components/subcontractor-management/subcontractor-management.component';
import { ClientManagementComponent } from './components/client-management/client-management.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { EditSubcontractorComponent } from './components/subcontractor-management/edit-subcontractor/edit-subcontractor.component';


@NgModule({
  declarations: [
    AppComponent,
    ClientsSitesUsersComponent,
    AuditComponent,
    AddClientComponent,
    AddSiteComponent,
    AddUserComponent,
    LoginComponent,
    SidenavComponent,
    HealthComponent,
    ReportingComponent,
    EditUserSitesComponent,
    TokenManagementComponent,
    AddSubcontractorComponent,
    QcReportComponent,
    QcReportDownloadComponent,
    UserManagementComponent,
    SiteManagementComponent,
    SubcontractorManagementComponent,
    ClientManagementComponent,
    LandingPageComponent,
    EditSubcontractorComponent,
    // ConfirmationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatChipsModule,
    FlexModule,
    MatIconModule,
    FormsModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatDividerModule,
    MatExpansionModule,
    MatTreeModule,
    DragDropModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthinterceptorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
