<mat-card class="mat-elevation-z8" style="z-index: 1004">
    <div fxLayout="column">
        <h2>Sub-Contractor</h2>
        <div>
            <label>Details for Sub-Contractor:</label>
            <div fxLayout="column">
                <!-- Name -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Company Name</mat-label>
                        <input matInput [(ngModel)]="selectedContractor.name">
                    </mat-form-field>
                </div>

                <!-- Address -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Company Address</mat-label>
                        <input matInput [(ngModel)]="selectedContractor.address">
                    </mat-form-field>
                </div>
                <!-- Number -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Contact Number</mat-label>
                        <input matInput [(ngModel)]="selectedContractor.contactNumber">
                    </mat-form-field>
                </div>
                <!-- Email -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Contact Email</mat-label>
                        <input matInput [(ngModel)]="selectedContractor.contactEmail">
                    </mat-form-field>
                </div>

                <!-- ClientDB -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Client</mat-label>
                        <mat-select [(ngModel)]="selectedContractor.clientdb">
                            <mat-option value="None">None</mat-option>
                            <mat-option *ngFor="let client of clients" [value]="client.clientName">{{client.clientName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>


                <button style="margin: 4px;" mat-raised-button color="primary" (click)="save()">
                    <mat-icon>save</mat-icon> Save Contractor
                </button>
                <button style="margin: 4px;" mat-raised-button color="warn" (click)="delete()">
                    <mat-icon>delete</mat-icon> Delete Contractor
                </button>
                <button style="margin: 4px;" mat-raised-button color="accent" (click)="closing()">
                    <mat-icon>cancel</mat-icon> Cancel
                </button>
            </div>
        </div>
    </div>
</mat-card>