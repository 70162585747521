import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-edit-subcontractor',
  templateUrl: './edit-subcontractor.component.html',
  styleUrls: ['./edit-subcontractor.component.scss']
})
export class EditSubcontractorComponent implements OnInit {
  selectedContractor: any;

  @Input() contractor: any = null;
  @Output() close = new EventEmitter();
  @Input() clients: any = [];

  isEdit: boolean = false;
  constructor(public webReq: WebRequestsService) { }

  ngOnInit(): void {
    if(this.contractor._id == null || this.contractor._id == undefined) {
      this.selectedContractor = {
        name: "",
        address: "",
        contactNumber: "",
        contactEmail: ""
      };
    } else {
      this.selectedContractor = this.contractor;
      this.isEdit = true;
    }
  }

  save() {
    if(this.isEdit) {
      this.webReq.updateContractor(this.selectedContractor).subscribe(res => {
        console.log(res)
        alert(res.message)
        this.close.emit();
      });
    } else {
      this.webReq.addContractor(this.selectedContractor).subscribe(res => {
        console.log(res)
        alert(res.message)
        this.close.emit();
      });
    }
    
  }

  delete() {
    this.webReq.deleteContractor(this.selectedContractor).subscribe(res => {
      console.log(res)
      alert(res.message)
      this.close.emit();
    });
  }

  closing() {
    this.close.emit();
  }
}
