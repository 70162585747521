import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

  filterValue = "";
  allUsers: any = [];
  filteredUsers: any = [];
  selectedUser: any = null;
  tempUser: any = {};
  addingUser = false;
  subcontractors = [];
  filteredContractors = [];
  addingUserSites = false;
  addingUserClients = false;
  siteList = [];
  allClients: any = [];
  allSites: any = [];

  displayedColumns = ['username', 'name', 'surname', 'email', 'cellphone', 'admin','vkeys','configurator','subcontractor', 'clientdb']
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(public webReq: WebRequestsService) { }

  ngOnInit(): void {
    this.getUsers();
    this.getSubcontractors();
    this.getAllSites();
    this.getAllClients();
  }

  getAllSites() {
    this.webReq.getAllSites().subscribe(sites => {
      this.siteList = sites;
      this.allSites = sites;
    });
  }

  getAllClients() {
    this.webReq.getAllClients().subscribe(clients => {
      this.allClients = clients;
    })
  }
  getUsers() {
    this.webReq.getAllUsers().subscribe(users => {
      this.allUsers = users;
      this.filteredUsers = users;
      this.dataSource.data = this.filteredUsers;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    })
  }
  applyUsersFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredUsers = this.allUsers.filter((x:any) => x.username.toLowerCase().includes(filterValue));
    if(filterValue == "") {
      this.filteredUsers = this.allUsers;
    }
    this.dataSource.data = this.filteredUsers;
  }

  getSubcontractors() {
    this.webReq.getAllSubcontractors().subscribe(contractors => {
      this.subcontractors = contractors;
      this.filteredContractors = this.subcontractors;
    });
  }

  resetPassword() {
    this.webReq.resetPassword(this.selectedUser).subscribe(res => {
      alert(res.message);
      this.webReq.getAllUsers().subscribe(users => {
        this.allUsers = users;
        this.selectedUser = {};
        this.addingUserSites = false;
        this.addingUserClients = false;
      })
    })
  }

  removeSiteFromUser(site:any) {
    const index = this.selectedUser.sites.indexOf(site);
    if(index>=0) {
      this.selectedUser.sites.splice(index, 1);
    }
  }

  removeClientFromUser(client:any) {
    const index = this.selectedUser.clientdb.indexOf(client);
    if(index>=0) {
      this.selectedUser.clientdb.splice(index, 1);
    }
  }

  updateUser() { // Should be able to update username, cellphone, clients, sites
    this.webReq.updateUser(this.selectedUser).subscribe(res => {
      alert(res.message);
      this.webReq.getAllUsers().subscribe(users => {
        this.allUsers = users;
        this.selectedUser = null;
        this.addingUserSites = false;
        this.addingUserClients = false;
      })
    })
  }

  deleteUser() {
    this.webReq.deleteUser(this.selectedUser).subscribe(res => {
      alert(res.message);
      this.webReq.getAllUsers().subscribe(users => {
        this.allUsers = users;
        this.selectedUser = {};
        this.addingUserSites = false;
        this.addingUserClients = false;
      })
    })
  }

}
