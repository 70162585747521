import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { tap,catchError } from 'rxjs/operators';


@Injectable()
export class AuthinterceptorInterceptor implements HttpInterceptor {

  constructor( public router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const modifiedRequest = request.clone({
      headers: request.headers.set('Authorization','Bearer ' + localStorage.getItem('access_token')),
    });
    return next.handle(modifiedRequest).pipe(tap(evt => {

    }),catchError(error => {
      console.log(error);
      if(error.status == 401 && error.statusText == "Unauthorized") {
        console.log("User not authorized")
        alert('Session Expired, please login again...');
        setTimeout(() => {
          this.router.navigate(['/login']);
        })
      }
      return of(error);
    }));
  }
}
