import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WebRequestsService } from 'src/app/services/web-requests.service';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: string = "";
  password: string = "";
  jwtData: any = "";
  constructor(public router: Router, public webReq: WebRequestsService) { }

  ngOnInit(): void {
  }
  login() {

    this.webReq.login({username:this.email, password: this.password}).subscribe(result => {
      console.log(result);
      this.jwtData = jwt_decode(result.access_token);
      if(this.jwtData.sysAdmin == true) {
        localStorage.setItem('username', this.jwtData.username)
        localStorage.setItem('email', this.jwtData.email)
        localStorage.setItem('access_token', result.access_token);
        localStorage.setItem('isLoggedIn', 'true');
        this.router.navigate(['/']);
      }
      else {
        alert('User not authorized');
      }

    })

    // if(this.email == 'werner@ethele.co.za' && this.password == 'pass') {
    //   localStorage.setItem('isLoggedIn', 'true');
    //   this.router.navigate(['']);
    // }
  }

}
