<mat-card class="mat-elevation-z8" style="z-index: 1005">
    <div fxLayout="column">
        <h2>User</h2>
        <div>
            <label>Details for User:</label>
            <div fxLayout="column">
                <!-- Client -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Client</mat-label>
                        <!-- <input matInput [(ngModel)]="selectedUser.email"> -->
                        <mat-select [(ngModel)]="selectedUser.clientdb">
                            <mat-option *ngFor="let client of clientList" [value]="client.clientName">{{client.clientName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- Email -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput [(ngModel)]="selectedUser.email">
                    </mat-form-field>
                </div>
                <!-- Username -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Username</mat-label>
                        <input matInput [(ngModel)]="selectedUser.username">
                    </mat-form-field>
                </div>
                 <!-- Name -->
                 <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Name</mat-label>
                        <input matInput [(ngModel)]="selectedUser.name">
                    </mat-form-field>
                </div>
                 <!-- Surname -->
                 <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Surname</mat-label>
                        <input matInput [(ngModel)]="selectedUser.surname">
                    </mat-form-field>
                </div>
                <!-- Admin -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-checkbox [(ngModel)]="selectedUser.isAdmin">Admin</mat-checkbox>
                </div>
                <!-- System Admin -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-checkbox [(ngModel)]="selectedUser.sysAdmin">System Admin</mat-checkbox>
                </div>
                <!-- Tokens -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-checkbox [(ngModel)]="selectedUser.generateTokens">Can Generate Tokens</mat-checkbox>
                </div>
                 <!-- Can Configure -->
                 <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-checkbox [(ngModel)]="selectedUser.canConfigure">Can Configure</mat-checkbox>
                </div>
                <!-- Cellphone -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Cellphone</mat-label>
                        <input matInput [(ngModel)]="selectedUser.cellphone">
                    </mat-form-field>
                </div>


                <button style="margin: 4px;" mat-raised-button color="primary" (click)="addUser()">
                    <mat-icon>save</mat-icon> Create User
                </button>
                <button style="margin: 4px;" mat-raised-button color="warn" (click)="closing()">
                    <mat-icon>cancel</mat-icon> Cancel
                </button>
            </div>
        </div>
    </div>
</mat-card>