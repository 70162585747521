<mat-sidenav-container>
    <mat-sidenav #drawer mode="over">
      <mat-nav-list>
        <a mat-list-item (click)="drawer.toggle()" [routerLink]="['']">
          <mat-icon>home</mat-icon> Home
        </a>
        <a mat-list-item (click)="drawer.toggle()" [routerLink]="['audit']">
          <mat-icon>manage_search</mat-icon> Logs
        </a>
        <a mat-list-item (click)="drawer.toggle()" [routerLink]="['health']">
          <mat-icon>monitor_heart</mat-icon> System Health
        </a>
        <a mat-list-item (click)="drawer.toggle()" [routerLink]="['reporting']">
          <mat-icon>summarize</mat-icon> Reporting
        </a>
        <a mat-list-item (click)="drawer.toggle()" [routerLink]="['token-management']">
          <mat-icon>key</mat-icon> Token Management
        </a>

        <a mat-list-item (click)="drawer.toggle()" [routerLink]="['login']">
          <mat-icon>logout</mat-icon> Logout
        </a>
      </mat-nav-list>
      <label style="position: absolute; bottom: 2vh; left: 8px">a label</label>
    </mat-sidenav>
    <mat-sidenav-content>
      
        <div class="custom-bg" fxLayout=column fxLayoutAlign="space-between stretch" fxFlex=100 style="background-color: grey;height: 100vh;">
        
        <mat-toolbar class="mat-elevation-z8" fxLayoutAlign="space-between center" style="height: 8vh !important; z-index: 3000;">
          <!-- <button mat-icon-button (click)="drawer.toggle()">
            <mat-icon>menu</mat-icon>
          </button> -->

          <button mat-icon-button (click)="openMenu()">
            <mat-icon>widgets</mat-icon>
          </button>
          <span><img src="../../../assets/ethele-white.png" style="max-width: 10vw;"></span>
          <span><img src="../../../assets/prism.png" style="max-height: 6vh; max-width: 10vw;"></span>
  
        </mat-toolbar>
        
        <div fxLayout="column"  style="top: 10vh; height: 80vh; overflow-y: auto;">
          <router-outlet></router-outlet>

          <div>
            <div class="wave"></div>
            <div class="wave"></div>
            <div class="wave"></div>
          </div>
        </div>

        <mat-toolbar class="mat-elevation-z8" style="height: 6vh !important; z-index: 3000;">
          <label style="position: absolute; right: 2vw; font-size: smaller; color:white">{{email}}</label>

        </mat-toolbar>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>


  <!-- Make hover menu as part of sidenav -->
<div class="backdrop" *ngIf="showHoverMenu">
  <div id="overlay-menu">

    <div class="close-button" fxLayout="column" fxLayoutAlign="center center" (click)="openMenu()">
      <mat-icon>close</mat-icon>
    </div>
    <div class="menu-container" fxLayout="column" fxLayoutAlign="space-evenly center">

      <div fxLayout="row" fxLayoutAlign="space-evenly center" style="margin-bottom: auto; margin-top:auto; width: 100%">

        <div class="container">
            <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToReporting()">
                <mat-icon fxLayoutAlign="center center">view_list</mat-icon>
                Reports
            </div>
        </div>

        <div class="container">
            <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToClients()">
                <mat-icon fxLayoutAlign="center center">contact_mail</mat-icon>
                Clients
            </div>
        </div>


        <div class="container">
            <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToVKeys()">
                <mat-icon fxLayoutAlign="center center">shield</mat-icon>
                Manage v-Keys
            </div>
        </div>


        <div class="container">
            <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToSites()">
                <mat-icon fxLayoutAlign="center center">domain</mat-icon>
                Manage Sites
            </div>
        </div>


    </div>

    <div fxLayout="row" fxLayoutAlign="space-evenly center" style=" margin-bottom:auto; width: 100%">

        <div class="container">
            <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToUsers()">
                <mat-icon fxLayoutAlign="center center">person</mat-icon>
                Manage Users
            </div>
        </div>

        <div class="container">
            <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToSubcontractors()">
                <mat-icon fxLayoutAlign="center center">group</mat-icon>
                Subcontractors
            </div>
        </div>

        <div class="container">
            <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToHealth()">
                <mat-icon fxLayoutAlign="center center">dvr</mat-icon>
                System Health
            </div>
        </div>


        <div class="container">
            <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToLogs()">
                <mat-icon fxLayoutAlign="center center">checklist</mat-icon>
                Test Logs
            </div>
        </div>



    </div>


    </div>
  </div>
</div>

<div id="logoutButton" (click)="logout()">
  <div fxLayout="row" fxLayoutAlign="center center">
    Logout <mat-icon>logout</mat-icon>
  </div>
</div>