import { Component, Input, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';

interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}

@Component({
  selector: 'app-qc-report-download',
  templateUrl: './qc-report-download.component.html',
  styleUrls: ['./qc-report-download.component.scss']
})
export class QcReportDownloadComponent implements OnInit {

  @Input() dsItems: any;
  @Input() unitItems: any;

  public fromDate: any;
  public toDate: any;

  constructor() { }

  ngOnInit(): void {
  }

  genReport() {
    console.log(this.dsItems.length);
    console.log(this.unitItems.length);
    let tempDSList = [];
    let tempUnitList = [];
    this.dsItems.forEach((item: any) => {
      if (new Date(item.time) <= new Date(this.toDate) && new Date(item.time) >= new Date(this.fromDate)) {
        tempDSList.push(item);
      }
    })
    this.unitItems.forEach((item: any) => {
      if (new Date(item.time) <= new Date(this.toDate) && new Date(item.time) >= new Date(this.fromDate)) {
        tempUnitList.push(item);
      }
    })
    console.log(tempDSList.length)
    console.log(tempUnitList.length)
  }

  createPDF() {
    var imgData = new Image();
    var imgData1 = new Image();
    imgData.src = 'assets/ethele.png'
    imgData1.src = 'assets/prism.png'
    const doc = new jsPDF() as jsPDFWithPlugin;
    doc.addImage(imgData1, 'png', 160, 10, 25, 25);
    doc.addImage(imgData, 'png', 162, 35, 20, 10);
    doc.line(10, 75, 200, 75)
    doc.setFontSize(32);
    doc.text("Prism Quality Control Report", 10, 30);
    doc.setFontSize(16);
    // doc.text("Site: " + localStorage.selectedBTSite + "("+this.siteIDFromUnit+")", 10, 50);
    doc.text("Date: " + new Date().toLocaleString(), 10, 40);
    doc.text("This report was generated by e-Thele SQD Networks (Pty) Ltd", 10, 85);
    doc.line(10, 290, 200, 290)

    var mytb = `<table id="mypdftable" style="background-color: orange">
    <thead><tr><th>Component</th><th>Result</th><th>Image</th></tr></thead>
      <tbody>
        <tr><td>Serial Number</td><td>' + actRes + '</td><td><img src="' + ActuatorImg.src + '" /></td></tr>
        <tr><td>Door Sensor</td><td>' + dsRes + '</td><td><img src="' + DoorSensorImg.src + '" /></td></tr>
        <tr><td>Tamper</td><td>' + tmpRes + '</td><td><img src="' + TamperImg.src + '" /></td></tr>
        <tr><td>Power Supply</td><td>' + psRes + '</td><td><img src="' + PowerSupplyImg.src + '" /></td></tr>
      </tbody>
    </table>`;

    var parser = new DOMParser();
    var oldBody = document.body;
    var myTBL = parser.parseFromString(mytb, 'text/html');
    document.body = myTBL.body

    doc.autoTable({
      html: '#mypdftable',
      bodyStyles: { minCellHeight: 30 },
      headStyles: { fillColor: '#ff5b0c' },
      startY: 90,
      didDrawCell: function (data) {
        if (data.column.index === 2 && data.cell.section === 'body') {
          var td = data.cell.raw?.valueOf();
          console.log(typeof (data.cell.raw?.valueOf()));
          var img = td?.valueOf();
          var dim = data.cell.height - data.cell.padding('vertical');
          var textPos = data.cell.getTextPos();
          var imgSrc = "";
          switch (data.row.index) {
            // case 0: imgSrc = ActuatorImg.src; break;
            // case 1: imgSrc = DoorSensorImg.src; break;
            // case 2: imgSrc = TamperImg.src; break;
            // case 3: imgSrc = PowerSupplyImg.src; break;
            default: break;
          }
          doc.addImage(imgSrc, textPos.x, textPos.y, dim, dim);
        }
      }
    });

  }

  jspdfGenerate() {
    const doc = new jsPDF() as jsPDFWithPlugin;
    const doc1 = new jsPDF() as jsPDFWithPlugin;
    const tableColumnHeaders = ['No.', 'Serial Number', 'Date Tested', 'Controller', 'Door Sensors','Actuator', 'DC-DC', 'Tampers', 'Power Supply', 'Version'];
    const tableColumnHeaders1 = ['No.', 'Serial Number', 'Date Tested', 'Result'];
    const tableData: any = [];
    const tableData1: any = [];
    var imgData = new Image();
    var imgData1 = new Image();
    imgData.src = 'assets/ethele.png'
    imgData1.src = 'assets/prism.png'
    doc.addImage(imgData1, 'png', 160, 10, 25, 25);
    doc.addImage(imgData, 'png', 162, 35, 20, 10);
    doc1.addImage(imgData1, 'png', 160, 10, 25, 25);
    doc1.addImage(imgData, 'png', 162, 35, 20, 10);
    let yOffset = 10;

    doc.text("Prism Units Report", 10, 30);
    doc1.text("Prism Door Sensors Report", 10, 30);
    doc.text("Date: " + new Date().toLocaleString(), 10, 40);
    doc.text("This report was generated by e-Thele SQD Networks (Pty) Ltd", 10, 65);
    doc1.text("Date: " + new Date().toLocaleString(), 10, 40);
    doc1.text("This report was generated by e-Thele SQD Networks (Pty) Ltd", 10, 65);

    this.dsItems.forEach((item: any, index: any) => {
      if (new Date(item.time) <= new Date(this.toDate) && new Date(item.time) >= new Date(this.fromDate) && item.result == '0') {
        const text = `${index + 1}. ${item.serialNumber}`;
        tableData1.push([index + 1, item.serialNumber.substring(1, item.serialNumber.length), item.time, 'Pass']);
      }
    })

    this.unitItems.forEach((item: any, index: any) => {
      if (new Date(item.time) <= new Date(this.toDate) && new Date(item.time) >= new Date(this.fromDate) && item.result == 'DC 0 ; Tamp 0 ; Act 13 ; OC 9') {
        const text = `${index + 1}. ${item.serialNumber}`;
        tableData.push([index + 1, item.serialNumber, item.time, 'Pass', 'Pass','Pass', 'Pass', 'Pass', 'Pass', item.firmwareVersion]);
      }
    })

    const columnWidths = [20, 100, 100, 20, 20, 20, 20];
    doc.autoTable({
      head: [tableColumnHeaders],
      body: tableData,
      startY: 85,
      // columnWidths
    })

    doc1.autoTable({
      head: [tableColumnHeaders1],
      body: tableData1,
      startY: 85,
      // columnWidths
    })

    doc.save('PRISM Test Report ' + new Date().toDateString() + '.pdf')
    doc1.save( 'Door Sensor Test Report ' + new Date().toDateString() + '.pdf')
  }

}
