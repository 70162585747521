<mat-card class="mat-elevation-z8" style="z-index: 1004">
    <div fxLayout="column">
        <h2>Add Client</h2>
        <div>
            <label>Details for Client:</label>
            <div fxLayout="column">
                <!-- Client Name -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Client Name</mat-label>
                        <input matInput [(ngModel)]="selectedClient.clientName">
                    </mat-form-field>
                </div>
                <!-- Client Code -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Client Code</mat-label>
                        <input matInput [(ngModel)]="selectedClient.clientCode">
                    </mat-form-field>
                </div>

                <button style="margin: 4px;" mat-raised-button color="primary" (click)="addClient()">
                    <mat-icon>save</mat-icon> Create Client
                </button>
                <button style="margin: 4px;" mat-raised-button color="warn" (click)="closing()">
                    <mat-icon>cancel</mat-icon> Cancel
                </button>
            </div>
        </div>
    </div>
</mat-card>