import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuditComponent } from './audit/audit.component';
import { ClientsSitesUsersComponent } from './components/clients-sites-users/clients-sites-users.component';
import { HealthComponent } from './components/health/health.component';
import { LoginComponent } from './components/login/login.component';
import { ReportingComponent } from './components/reporting/reporting.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { TokenManagementComponent } from './components/token-management/token-management.component';
import { AuthguardGuard } from './guards/authguard.guard';
import { QcReportComponent } from './components/qc-report/qc-report.component';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { SiteManagementComponent } from './components/site-management/site-management.component';
import { ClientManagementComponent } from './components/client-management/client-management.component';
import { SubcontractorManagementComponent } from './components/subcontractor-management/subcontractor-management.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';

const routes: Routes = [

  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    component: SidenavComponent,
    children: [
      {
        path: 'audit',
        component: QcReportComponent,
        canActivate: [AuthguardGuard]
      },
      // {
      //   path: '',
      //   component: ClientsSitesUsersComponent,
      //   canActivate: [AuthguardGuard]
      // },
      {
        path: '',
        component: LandingPageComponent,
        canActivate: [AuthguardGuard]
      },
      {
        path: 'health',
        component: HealthComponent,
        canActivate: [AuthguardGuard]
      },
      {
        path: 'reporting',
        component: ReportingComponent,
        canActivate: [AuthguardGuard]
      },
      {
        path: 'token-management',
        component: TokenManagementComponent
      },
      {
        path: 'user-management',
        component: UserManagementComponent
      },
      {
        path: 'site-management',
        component: SiteManagementComponent
      },
      {
        path: 'client-management',
        component: ClientManagementComponent
      },
      {
        path: 'subcontractor-management',
        component: SubcontractorManagementComponent
      }

    ]
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
