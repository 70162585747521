<div fxLayout="column" fxLayoutAlign="center space-evenly" id="bigBox"
    style="width: 100vw; height: 100%; position: absolute; top:0; z-index: 0;">
    <div fxLayout="row" fxLayoutAlign="space-evenly center" style="margin-bottom: auto; margin-top:auto">

        <div class="container">
            <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToReporting()">
                <mat-icon fxLayoutAlign="center center">view_list</mat-icon>
                Reports
            </div>
        </div>

        <div class="container">
            <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToClients()">
                <mat-icon fxLayoutAlign="center center">contact_mail</mat-icon>
                Clients
            </div>
        </div>


        <div class="container">
            <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToVKeys()">
                <mat-icon fxLayoutAlign="center center">shield</mat-icon>
                Manage v-Keys
            </div>
        </div>


        <div class="container">
            <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToSites()">
                <mat-icon fxLayoutAlign="center center">domain</mat-icon>
                Manage Sites
            </div>
        </div>


    </div>

    <div fxLayout="row" fxLayoutAlign="space-evenly center" style=" margin-bottom:auto">

        <div class="container">
            <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToUsers()">
                <mat-icon fxLayoutAlign="center center">person</mat-icon>
                Manage Users
            </div>
        </div>

        <div class="container">
            <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToSubcontractors()">
                <mat-icon fxLayoutAlign="center center">group</mat-icon>
                Subcontractors
            </div>
        </div>

        <div class="container">
            <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToHealth()">
                <mat-icon fxLayoutAlign="center center">dvr</mat-icon>
                System Health
            </div>
        </div>


        <div class="container">
            <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToLogs()">
                <mat-icon fxLayoutAlign="center center">checklist</mat-icon>
                Test Logs
            </div>
        </div>



    </div>

    </div>

        <img class="bg-img" src="../../assets/prism.png">