import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss']
})
export class ReportingComponent implements OnInit {

  reportUrl:any = "";
  apiReportUrl:any = "";
  alarmReportUrl: any = "";
  devicePingReportUrl: any = "";
  clientList:any = [];
  selectedClient = "ethele";
  mainReport: boolean = true;
  activeReport: ActiveReport = ActiveReport.Actions;

  constructor(private sanitizer: DomSanitizer, public webReq: WebRequestsService) { }

  ngOnInit(): void {
    this.webReq.getAllClients().subscribe((res:any) => {
      this.clientList=res;
    })
    this.getReportUrl("ethele");
  }

  getReportUrl(client:any) {
    // var initUrl = "https://datastudio.google.com/embed/reporting/9c1968db-891d-4a5e-9f59-445e9c9f0734/page/45p3C";
    this.activeReport = 0;
    var initUrl = "https://datastudio.google.com/embed/u/0/reporting/8394e230-7a17-438e-bebd-3b1841f61925/page/WTUCD";
    
    console.log("Initial report url")
    console.log(initUrl)
    var params = {
      "ds2.clientdb": client
    };
    var paramsAsString = JSON.stringify(params);
    var encodedParams = encodeURIComponent(paramsAsString)
    console.log("Encoded params:");
    console.log(encodedParams);

    this.reportUrl = this.sanitizer.bypassSecurityTrustResourceUrl(initUrl+"?params="+encodedParams);
    console.log("Report url final");
    console.log(this.reportUrl.toString());
  }

  getAPIReportUrl(client:any) {
    this.activeReport = 1;
    var initUrl = "https://lookerstudio.google.com/embed/reporting/55bebaf1-488c-4115-b8da-b018f847f246/page/WTUCD";
    var params = {
      "ds2.clientdb":client,
      "ds26.clientdb":client
    };
    var paramsAsString = JSON.stringify(params);
    var encodedParams = encodeURIComponent(paramsAsString);
    this.apiReportUrl = this.sanitizer.bypassSecurityTrustResourceUrl(initUrl+"?params="+encodedParams);
  }
  
  getAlarmReportUrl(client:any) {
    this.activeReport = 2;
    var initUrl = "https://lookerstudio.google.com/embed/reporting/0b18c8a6-0548-4a16-8116-dfae59233ca9/page/HJGED";
    var params = {
      "ds0.clientdb":client,
    };
    var paramsAsString = JSON.stringify(params);
    var encodedParams = encodeURIComponent(paramsAsString);
    this.alarmReportUrl = this.sanitizer.bypassSecurityTrustResourceUrl(initUrl+"?params="+encodedParams);
  }

  getDevicePingUrl() {
    this.activeReport = 3;
    var initUrl = "https://lookerstudio.google.com/embed/reporting/0b18c8a6-0548-4a16-8116-dfae59233ca9/page/HJGED";
    this.devicePingReportUrl = this.sanitizer.bypassSecurityTrustResourceUrl(initUrl);

  }

}

enum ActiveReport {
  Actions = 0,
  API = 1,
  Alarms = 2,
  Pings = 3
}
