import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  email: string = "";
  showHoverMenu = false;
  constructor(public router: Router) { }

  ngOnInit(): void {
    var token:any = localStorage.getItem('access_token');
    var user:any = jwt_decode(token);
    this.email = user.email;
  }

  navToHealth() {
    this.showHoverMenu = false;
    this.router.navigate(['/health'])
  }

  navToReporting() {
    this.showHoverMenu = false;

    this.router.navigate(['/reporting'])
  }

  navToLogs() {
    this.showHoverMenu = false;
    this.router.navigate(['/audit']);
  }

  navToSites() {
    this.showHoverMenu = false;
    this.router.navigate(['/site-management'])
  }

  navToUsers() {
    this.showHoverMenu = false;
    this.router.navigate(['/user-management'])
  }

  navToVKeys() {
    this.showHoverMenu = false;
    this.router.navigate(['/token-management'])
  }

  navToClients() {
    this.showHoverMenu = false;
    this.router.navigate(['/client-management'])
  }
  navToSubcontractors() {
    this.showHoverMenu = false;
    this.router.navigate(['/subcontractor-management'])
  }
  logout() {
    this.router.navigate(['/login']);
  }

  openMenu() {
    if(this.showHoverMenu) {
      this.showHoverMenu = false;
    } else {
      this.showHoverMenu = true;
    }
  }
}
